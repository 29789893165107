import { Component } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'inm-popup-http-error',
  templateUrl: './inm-popup-http-error.component.html',
  styleUrls: ['./inm-popup-http-error.component.scss']
})
export class InmPopupHttpErrorComponent {
  constructor(public bsModalRef: BsModalRef) {}
  /**
   * close pop up
   */
  public closePopUp() {
    this.bsModalRef.hide();
  }
}
