import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AuthService } from '../../services/auth-service/auth.service';
import { TitleService } from '../../services/title-service/title.service';
import { getIsAuthenticated, IState } from '../../shared-store';

@Component({
  selector: 'inm-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  public isAuthenticated$: Observable<boolean>;

  constructor(
    protected store: Store<IState>,
    protected authService: AuthService,
    private readonly router: Router,
    private readonly titleService: TitleService
  ) {
    this.isAuthenticated$ = this.store.select(getIsAuthenticated);
    this.authService.init();
    this.navigationInterceptor();
  }

  /**
   * Listen to router events
   */
  public navigationInterceptor(): void {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.titleService.setDocumentTitle();
      }
    });
  }
}
