import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ROUTES } from './app.constants';
import { ErrorPageComponent } from './core/components/error-page/error-page.component';
import { LayoutComponent } from './core/components/layout/layout.component';
import { AuthGuardService } from './services/auth-guard/auth-guard.service';
import { DeactivateRouteGuard } from './services/deactivate-route/deactivate-route.service';
import { ExternalPlaceholderComponent } from './shared/components/external-placeholder/external-placeholder.component';
import { AuthAction, AuthResource } from './shared/models/auth.model';

const routes: Routes = [
  {
    path: ROUTES.ERROR,
    component: ErrorPageComponent,
    data: {
      title: 'Help & Support'
    }
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: ROUTES.ROOT,
        loadChildren: () => import('./welcome/welcome.module').then(m => m.WelcomeModule),
        canActivate: [AuthGuardService],
        canActivateChild: [AuthGuardService],
        data: {
          title: 'pageTitles.homeTitle'
        }
      },
      {
        path: ROUTES.UPLOAD,
        loadChildren: () => import('./upload/upload.module').then(m => m.UploadModule),
        canActivate: [AuthGuardService],
        canActivateChild: [AuthGuardService],
        data: {
          title: 'pageTitles.uploadTitle',
          access: [[AuthResource.Relationship_Contract, AuthAction.Import]]
        }
      },
      {
        path: ROUTES.CONTRACT,
        loadChildren: () => import('./customers/normal-contract.module').then(m => m.NormalContractModule),
        canActivate: [AuthGuardService],
        canActivateChild: [AuthGuardService],
        data: {
          title: 'pageTitles.contractTitle',
          access: [[AuthResource.Relationship_Contract, AuthAction.Read]]
        }
      },
      {
        path: ROUTES.BU_CONTRACT,
        loadChildren: () => import('./customers/bu-contract.module').then(m => m.BUContractModule),
        canActivate: [AuthGuardService],
        canActivateChild: [AuthGuardService],
        data: {
          title: 'pageTitles.buContractTitle',
          access: [[AuthResource.Relationship_Contract_DEC, AuthAction.Read]]
        }
      },
      {
        path: ROUTES.BU_EXCLUSIONS,
        loadChildren: () => import('./bu-exclusions/bu-exclusions.module').then(m => m.BUExclusionsModule),
        canActivate: [AuthGuardService],
        canActivateChild: [AuthGuardService],
        data: {
          title: 'pageTitles.buExclusionsTitle',
          access: [[AuthResource.Relationship_Contract_DEC, AuthAction.Read]]
        }
      },
      {
        path: ROUTES.MIGRATION,
        loadChildren: () => import('./migration-tools/migration-tools.module').then(m => m.MigrationToolsModule),
        canActivate: [AuthGuardService],
        canActivateChild: [AuthGuardService],
        data: {
          title: 'pageTitles.migrationTitle',
          access: [[AuthResource.CMS_UITab_Contract, AuthAction.Export]]
        }
      },
      {
        path: ROUTES.MAINTENANCE,
        loadChildren: () => import('./maintenance-tool/maintenance-tool.module').then(m => m.MaintenanceToolModule),
        canActivate: [AuthGuardService],
        canActivateChild: [AuthGuardService],
        data: {
          title: 'pageTitles.maintenanceToolTitle',
          access: [[AuthResource.CMS_UITab_ReferenceData, AuthAction.Read]]
        }
      },

      // HELP ROUTES
      {
        path: ROUTES.EXTERNAL,
        component: ExternalPlaceholderComponent
      }
    ]
  },
  {
    path: '**',
    redirectTo: `/${ROUTES.NOT_FOUND}`
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: 'enabled' })],
  declarations: [],
  exports: [RouterModule],
  providers: [DeactivateRouteGuard]
})
export class AppRoutingModule {}
