import { IMaintenanceTool } from '../../../shared/models/maintenance-tool.model';
import {
  MaintenanceToolActions,
  MaintenanceToolActionTypes
} from '../../actions/maintenance-tool/maintenance-tool.actions';

import { initialPredefinedDataValue } from '../../../maintenance-tool/maintenance-tool.constant';

export interface IMaintenanceToolState {
  loaded: boolean;
  loading: boolean;
  maintenanceTool: IMaintenanceTool;
}

export const initialState: IMaintenanceToolState = {
  loaded: false,
  loading: false,
  maintenanceTool: initialPredefinedDataValue
};

export function maintenanceToolReducer(state = initialState, action: MaintenanceToolActions): IMaintenanceToolState {
  switch (action.type) {
    case MaintenanceToolActionTypes.MaintenanceTool: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }

    case MaintenanceToolActionTypes.MaintenanceToolSuccess: {
      return {
        ...state,
        loaded: true,
        loading: false,
        maintenanceTool: action.payload
      };
    }

    case MaintenanceToolActionTypes.Reset: {
      return {
        ...state,
        loaded: false,
        loading: false,
        maintenanceTool: initialPredefinedDataValue
      };
    }
    default: {
      return state;
    }
  }
}
