import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { defineLocale } from 'ngx-bootstrap';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { enGbLocale } from 'ngx-bootstrap/locale';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { CUSTOM_INVALID_DATE } from '../app.constants';
import { MaterialModule } from './modules/material/material.module';

// Use enGbLocale to change ngx-bootstrap `Invalid date` message to blank
enGbLocale.invalidDate = '';
defineLocale(CUSTOM_INVALID_DATE, enGbLocale);

@NgModule({
  imports: [
    CommonModule,
    CollapseModule.forRoot(),
    ProgressbarModule.forRoot(),
    PaginationModule.forRoot(),
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    MaterialModule
  ],
  exports: [
    CollapseModule,
    PaginationModule,
    BsDropdownModule,
    ModalModule,
    ProgressbarModule,
    MaterialModule,
    BsDatepickerModule
  ],
  declarations: []
})
export class SharedLibModule {
  constructor(private readonly localeService: BsLocaleService) {
    this.localeService.use(CUSTOM_INVALID_DATE);
  }
}
