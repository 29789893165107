import { Action } from '@ngrx/store';
import { concat, Observable, of, OperatorFunction, pipe } from 'rxjs';
import { catchError, concatMap, exhaustMap, switchMap } from 'rxjs/operators';
import { createErrorNotification } from '../../shared/models/notification.model';
import {
  NotificationAddItem,
  NotificationHideSpinner,
  NotificationShowSpinner
} from '../actions/notifications/notifications.actions';

/**
 * Helper functions for writing effects.
 *
 * These helpers capture logic that previously was duplicted in each effect.
 */

/**
 * Returns an observable that wraps the actions produced by a task in
 * show and hide spinner actions, and adds basic error catching.
 * For use in effects, though you probably want to use `switchSpinner` or
 * `concatSpinner` instead of using this directly
 *
 * @param type The action type triggered the task.
 * @param task A task that maps from one action to a stream of others.
 */
export function withSpinner(type: string, task: Observable<Action>) {
  return concat(
    of(new NotificationShowSpinner(type)),
    task.pipe(catchError(error => of(new NotificationAddItem(createErrorNotification(error, type))))),
    of(new NotificationHideSpinner(type))
  );
}

/**
 * Shows a spinner while waiting for a task to complete. Incoming actions execute
 * a new task immediately, as per `switchMap` logic. For use in effects.
 *
 * @param task Task that maps from one action to a stream of others.
 */
export function switchSpinner<T extends Action>(task: (action: T) => Observable<Action>): OperatorFunction<T, Action> {
  return pipe(switchMap((action: T) => withSpinner(action.type, task(action))));
}

/**
 * Shows a spinner while waiting for a task to complete. Incoming actions are
 * queued before executing, as per `concatMap` logic. For use in effects.
 *
 * @param task Task that maps from one action to a stream of others.
 */
export function concatSpinner<T extends Action>(task: (action: T) => Observable<Action>): OperatorFunction<T, Action> {
  return pipe(concatMap((action: T) => withSpinner(action.type, task(action))));
}

/**
 * Shows a spinner while waiting for a task to complete. Incoming actions are
 * discarded if one is already executing, as per `exhaustMap` logic. For use in effects.
 *
 * @param task Task that maps from one action to a stream of others.
 */
export function exhaustSpinner<T extends Action>(task: (action: T) => Observable<Action>): OperatorFunction<T, Action> {
  return pipe(exhaustMap((action: T) => withSpinner(action.type, task(action))));
}
