import { Component, EventEmitter, Input, Output } from '@angular/core';
import { INotification } from '../../models/notification.model';

@Component({
  selector: 'inm-notification',
  templateUrl: './inm-notification.component.html',
  styleUrls: ['./inm-notification.component.scss']
})
export class InmNotificationComponent {
  @Input() public notification: INotification = null;
  @Output() public closeMessage = new EventEmitter();

  public destroyNotification() {
    this.closeMessage.emit(this.notification);
  }
}
