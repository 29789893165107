import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { IconModule } from '../../icon/icon.module';
import { StaticContentPipeModule } from '../../pipes/static-content/static-content-pipe.module';
import { InmAlertDialogComponent } from './components/inm-alert-dialog/inm-alert-dialog.component';
import { InmConfirmDialogComponent } from './components/inm-confirm-dialog/inm-confirm-dialog.component';
import { InmUploadErrorDialogComponent } from './components/inm-uploaderror-dialog/inm-uploaderror-dialog';

@NgModule({
  imports: [IconModule, CommonModule, MatDialogModule, FormsModule, StaticContentPipeModule],
  declarations: [InmConfirmDialogComponent, InmAlertDialogComponent, InmUploadErrorDialogComponent]
})
export class DialogModule {}
