import { Component, Input } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: '.inm-tr-no-record',
  templateUrl: './no-records.component.html',
  styleUrls: ['./no-records.component.scss']
})
export class InmTableNoRecordsComponent {
  /**
   * the TD size
   * @type {number}
   */
  @Input()
  public colspan = 1;
}
