import { initialClearContractValue, initialContractValue } from '../../../migration-tools/migration-tools.constants';
import { IClearContract } from '../../../shared/models/clear-contract.model';
import { IExportContract } from '../../../shared/models/export-contract.model';
import { MigrationActions, MigrationActionTypes } from '../../actions/migration/migration.actions';

export interface IMigrationState {
  loaded: boolean;
  loading: boolean;
  error: string;
  exportContractData: IExportContract;
  clearContractData: IClearContract;
}

export const initialState: IMigrationState = {
  loaded: false,
  loading: true,
  error: '',
  exportContractData: initialContractValue,
  clearContractData: initialClearContractValue
};

export function migrationReducer(state = initialState, action: MigrationActions) {
  switch (action.type) {
    case MigrationActionTypes.ExportContractData:
    case MigrationActionTypes.ClearContractData: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }

    case MigrationActionTypes.ExportContractDataSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        exportContractData: action.payload
      };
    }

    case MigrationActionTypes.ClearContractDataSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        clearContractData: action.payload
      };
    }

    case MigrationActionTypes.Error: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.payload,
        exportContractData: initialContractValue,
        clearContractData: initialClearContractValue
      };
    }
    case MigrationActionTypes.Reset: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: '',
        exportContractData: initialContractValue,
        clearContractData: initialClearContractValue
      };
    }

    default: {
      return state;
    }
  }
}
