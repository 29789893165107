import { RouterNavigatedAction } from '@ngrx/router-store';
import { Action } from '@ngrx/store';
import { INotification } from '../../../shared/models/notification.model';

/**
 * Notification actions
 */
export enum NotificationActionTypes {
  NOTIFICATION_ADD_ITEM = 'NOTIFICATION_ADD_ITEM',
  NOTIFICATION_DESTROY_ITEM = 'NOTIFICATION_DESTROY_ITEM',
  NOTIFICATION_SHOW_SPINNER = 'NOTIFICATION_SHOW_SPINNER',
  NOTIFICATION_HIDE_SPINNER = 'NOTIFICATION_HIDE_SPINNER',
  NOTIFICATION_CLEAR_ALL = 'NOTIFICATION_CLEAR_ALL'
}

/**
 * Notification Actions union
 */
export type NotificationActionUnion =
  | NotificationAddItem
  | NotificationDestroyItem
  | NotificationShowSpinner
  | NotificationHideSpinner
  | NotificationClearAll
  | RouterNavigatedAction;

/**
 * Load error in store
 */
export class NotificationAddItem implements Action {
  public readonly type = NotificationActionTypes.NOTIFICATION_ADD_ITEM;
  constructor(public payload: INotification) {}
}

/**
 * Destroy error in store
 */
export class NotificationDestroyItem implements Action {
  public readonly type = NotificationActionTypes.NOTIFICATION_DESTROY_ITEM;
  constructor(public payload: INotification) {}
}
/**
 * Set 'showSpinner' to true
 */
export class NotificationShowSpinner implements Action {
  public readonly type = NotificationActionTypes.NOTIFICATION_SHOW_SPINNER;
  constructor(public payload?: symbol | string) {}
}

/**
 * Set 'showSpinner' to false
 */
export class NotificationHideSpinner implements Action {
  public readonly type = NotificationActionTypes.NOTIFICATION_HIDE_SPINNER;
  constructor(public payload?: symbol | string) {}
}

/**
 * Clear the collection of notificaitons
 */
export class NotificationClearAll implements Action {
  public readonly type = NotificationActionTypes.NOTIFICATION_CLEAR_ALL;
}
