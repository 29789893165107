import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError, first } from 'rxjs/operators';

import { getIsAuthenticated, IState } from '../../shared-store/';
import { RedirectLogin } from '../../shared-store/actions/auth/auth.actions';

@Injectable({
  providedIn: 'root'
})
export class HttpRequestInteceptorError implements HttpInterceptor {
  public isAuthenticated$: Observable<boolean>;
  constructor(private readonly store: Store<IState>, private readonly router: Router) {
    this.isAuthenticated$ = this.store.select(getIsAuthenticated);
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        this.isAuthenticated$.pipe(first()).subscribe(isAuthenticated => {
          if (isAuthenticated) {
            if (error.status === 401) {
              this.store.dispatch(new RedirectLogin(true));
              return;
            }

            if (error.status === 403) {
              this.router.navigate(['error/not_authorized/403']);
              return;
            }
            return throwError(error);
          }

          if (error.status === 401) {
            this.store.dispatch(new RedirectLogin());
            return;
          }

          this.router.navigate(['error/api_unavailable/http_generic_error']);
        });

        // this will let effects to handle the error
        return throwError(error);
      })
    );
  }
}
