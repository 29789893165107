import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

import { IPermission } from '../../shared/models/auth.model';
import { IRole } from '../../shared/models/role.model';
import { IScope } from '../../shared/models/scope.model';
import { IUser } from '../../shared/models/user.model';
import { RestApiService } from '../rest-api-service/rest-api.service';

@Injectable()
export class UserService extends RestApiService {
  constructor(http: HttpClient) {
    super(http);
    this.baseUrl = environment.api.userManagementBase;
  }

  public getUser(): Observable<IUser> {
    return this.get('');
  }

  public getUsers(): Observable<IUser[]> {
    return this.get('');
  }

  public createUser(user: IUser): Observable<IUser> {
    return this.post('', user);
  }

  public updateUser(user: IUser): Observable<IUser> {
    return this.patch('', user);
  }

  public deleteUser(): Observable<Response> {
    return this.delete('');
  }

  public addUserToGroup(user: IUser) {
    this.put('', user);
  }

  public removeUserFromGroup() {
    this.delete('');
  }

  public getRolesByUser(): Observable<IRole[]> {
    return this.get('');
  }

  public getRoleByUser(): Observable<IRole> {
    return this.get('');
  }

  public addRoleToUser(role: IRole): Observable<IRole> {
    return this.post('', role);
  }

  public removeRoleFromUser(): Observable<IRole> {
    return this.get('');
  }

  public addScopeToUserAndRole(scope: IScope): Observable<IRole> {
    return this.post('', scope);
  }

  public removeScopeFromUserAndRole(): Observable<IRole> {
    return this.get('');
  }

  public getAvailableRolesByUser(): Observable<IRole[]> {
    return this.get('');
  }

  public addAvailableRoleToUser(role: IRole): Observable<IRole> {
    return this.post('', role);
  }

  public removeAvailableRoleFromUser(): Observable<IRole> {
    return this.get('');
  }

  /**
   * @description Gets current user and roles then returns the merged I... object
   * @param id
   */
  public getCurrentUserDetail(): Observable<IUser> {
    const $user = this.get(environment.api.profileUrl);
    return $user.pipe(map(user => user));
  }

  public getUserAccessPermissions(): Observable<IPermission[]> {
    return this.get(environment.api.access);
  }
}
