import { Component, Input } from '@angular/core';

@Component({
  selector: 'inm-input-container',
  templateUrl: './inm-input-container.component.html',
  styleUrls: ['./inm-input-container.component.scss']
})
export class InmInputContainerComponent {
  @Input() public label: string;
}
