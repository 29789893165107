import { Component, OnDestroy, Renderer2 } from '@angular/core';

@Component({
  selector: 'inm-overlay-spinner',
  template: `
    <inm-loading-spinner class="inm-spinner-blue"></inm-loading-spinner>
  `,
  styleUrls: ['./inm-overlay-spinner.component.scss']
})
export class InmOverlaySpinnerComponent implements OnDestroy {
  constructor(public renderer: Renderer2) {
    const bodyElement: HTMLBodyElement = this.getDocumentBodySelector();
    this.renderer.setStyle(bodyElement, 'overflow', 'hidden');
  }

  public ngOnDestroy() {
    const bodyElement: HTMLBodyElement = this.getDocumentBodySelector();
    this.renderer.removeStyle(bodyElement, 'overflow');
  }

  public getDocumentBodySelector() {
    return document.querySelector('body');
  }
}
