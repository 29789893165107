import { Action } from '@ngrx/store';

import { IMaintenanceTool } from '../../../shared/models/maintenance-tool.model';

export enum MaintenanceToolActionTypes {
  MaintenanceTool = 'MAINTENANCE_TOOL',
  MaintenanceToolSuccess = 'MAINTENANCE_TOOL_SUCCESS',
  Error = 'MAINTENANCE_TOOL_ERROR',
  Reset = 'MAINTENANCE_TOOL_RESET_STATE'
}

/**
 * Create/Update Person to Person Actions
 */
export class MaintenanceTool implements Action {
  public readonly type = MaintenanceToolActionTypes.MaintenanceTool;
  constructor(public payload: any) {}
}

export class MaintenanceToolSuccess implements Action {
  public readonly type = MaintenanceToolActionTypes.MaintenanceToolSuccess;
  constructor(public payload: IMaintenanceTool) {}
}

export class Error implements Action {
  public readonly type = MaintenanceToolActionTypes.Error;
  constructor(public payload: any) {}
}

export class Reset implements Action {
  public readonly type = MaintenanceToolActionTypes.Reset;
}

export type MaintenanceToolActions = MaintenanceTool | MaintenanceToolSuccess | Error | Reset;
