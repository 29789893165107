import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'inm-external-placeholder',
  templateUrl: './external-placeholder.component.html',
  styleUrls: ['./external-placeholder.component.scss']
})
export class ExternalPlaceholderComponent implements OnInit {
  public destination;
  constructor(private readonly activatedRoute: ActivatedRoute) {}

  public ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.destination = params.destination;
    });
  }
}
