import { IUploadResponse } from '../../../shared/models/upload-file.model';
import { UploadFileActions, UploadFileActionTypes } from '../../actions/upload-file/upload-file.actions';

export interface IUploadFileState {
  loaded: boolean;
  loading: boolean;
  uploadErrors: string;
  uploadSuccess: IUploadResponse;
}

const uploadresponse: IUploadResponse = {
  validationdata: [],
  error: { code: 0, description: '' },
  data: []
};

export const initialState: IUploadFileState = {
  loaded: false,
  loading: false,
  uploadErrors: '',
  uploadSuccess: uploadresponse
};

export function uploadFileReducer(state = initialState, action: UploadFileActions): IUploadFileState {
  switch (action.type) {
    case UploadFileActionTypes.UploadFile: {
      return {
        ...state,
        loading: true,
        uploadSuccess: uploadresponse
      };
    }

    case UploadFileActionTypes.UploadFileSuccess: {
      return {
        ...state,
        loaded: true,
        loading: false,
        uploadSuccess: action.payload
      };
    }

    case UploadFileActionTypes.UploadFileFail: {
      return {
        ...state,
        loading: false,
        loaded: true,
        uploadErrors: action.payload,
        uploadSuccess: uploadresponse
      };
    }

    case UploadFileActionTypes.Reset: {
      return {
        ...state,
        loaded: false,
        loading: false,
        uploadErrors: '',
        uploadSuccess: uploadresponse
      };
    }
    default: {
      return state;
    }
  }
}
