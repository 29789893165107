import { Component, Input } from '@angular/core';

@Component({
  selector: 'inm-loading-spinner',
  templateUrl: './inm-loading-spinner.component.html',
  styleUrls: ['./inm-loading-spinner.component.scss']
})
export class InmLoadingSpinnerComponent {
  public segments = Array.from({ length: 30 }, (_, i) => ({
    opacity: i * (1 / 30),
    rotation: i * 12
  }));

  @Input()
  public thickness = 20;

  public get ringClipPath() {
    return `
      M 0,100
      A 100,100 0,1,1 200,100
      A 100,100 0,1,1 0,100
      z 
      M ${this.thickness},100
      A ${100 - this.thickness}, ${100 - this.thickness} 0,1,1 ${200 - this.thickness},100 
      A ${100 - this.thickness}, ${100 - this.thickness} 0,1,1 ${this.thickness},100
      z
    `;
  }
}
