import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from '../shared-store/effects/auth-access-effects/auth.effects';
import { IconModule } from '../shared/icon/icon.module';
import { SharedModule } from '../shared/shared.module';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { LayoutComponent } from './components/layout/layout.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { AppComponent } from './containers/app.component';

const INM_STORE_EFFECTS = [AuthEffects];
@NgModule({
  imports: [CommonModule, RouterModule, SharedModule, IconModule, EffectsModule.forFeature([...INM_STORE_EFFECTS])],
  declarations: [
    AppComponent,
    SideMenuComponent,
    HeaderComponent,
    LayoutComponent,
    FooterComponent,
    ErrorPageComponent
  ],
  exports: [AppComponent, SideMenuComponent, HeaderComponent, LayoutComponent, FooterComponent, ErrorPageComponent]
})
export class CoreModule {
  public static forRoot() {
    return {
      ngModule: CoreModule,
      providers: []
    };
  }
}
