import { Action } from '@ngrx/store';

import { IUploadResponse } from '../../../shared/models/upload-file.model';

export enum UploadFileActionTypes {
  UploadFile = 'UPLOAD_FILE',
  UploadFileSuccess = 'UPLOAD_FILE_SUCCESS',
  UploadFileFail = 'UPLOAD_FILE_FAIL',
  Reset = 'UPLOAD_FILE_RESET_STATE'
}

/**
 * Create/Update Person to Person Actions
 */
export class UploadFile implements Action {
  public readonly type = UploadFileActionTypes.UploadFile;
  constructor(public payload: FormData, public user: any, public uploadType: any) {}
}

export class UploadFileSuccess implements Action {
  public readonly type = UploadFileActionTypes.UploadFileSuccess;

  constructor(public payload: IUploadResponse) {}
}

export class UploadFileFail implements Action {
  public readonly type = UploadFileActionTypes.UploadFileFail;

  constructor(public payload: any) {}
}
export class Reset implements Action {
  public readonly type = UploadFileActionTypes.Reset;
}

export type UploadFileActions = UploadFile | UploadFileSuccess | UploadFileFail | Reset;
