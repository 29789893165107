import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export abstract class RestApiService {
  public baseUrl = environment.api.baseUrl;
  public headers = new HttpHeaders({ Authorization: '' });

  public readonly options = { headers: this.headers };

  constructor(private readonly http: HttpClient) {}

  protected get(relativeUrl: string, headers = this.options): Observable<any> {
    return this.http.get<any>(this.getApiUrl(relativeUrl), headers);
  }

  protected post(relativeUrl: string, payload: any, headers = this.options): Observable<any> {
    return this.http.post(this.getApiUrl(relativeUrl), payload, headers);
  }

  protected put(relativeUrl: string, payload: any, headers = this.options): Observable<any> {
    return this.http.put(this.getApiUrl(relativeUrl), payload, headers);
  }

  protected patch(relativeUrl: string, payload: any, headers = this.options): Observable<any> {
    return this.http.patch(this.getApiUrl(relativeUrl), payload, headers);
  }

  protected delete(relativeUrl: string, headers = this.options): Observable<any> {
    return this.http.delete(this.getApiUrl(relativeUrl), headers);
  }

  protected getApiUrl(relativeUrl) {
    return `${this.baseUrl}${relativeUrl}`;
  }
}
