import { Component } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

@Component({
  selector: 'inm-inm-toastr',
  templateUrl: './inm-toastr.component.html',
  styleUrls: ['./inm-toastr.component.scss']
})
export class InmToastrComponent extends Toast {
  constructor(protected toastrService: ToastrService, public toastPackage: ToastPackage) {
    super(toastrService, toastPackage);
  }
}
