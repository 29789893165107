import { ChangeDetectorRef, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { delay } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import * as appReducers from '../../../shared-store';
import { NotificationDestroyItem } from '../../../shared-store/actions/notifications/notifications.actions';
import { INotification } from '../../../shared/models/notification.model';

@Component({
  selector: 'inm-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent {
  public notifications$: Observable<INotification[]>;
  public showSpinner$: Observable<boolean>;
  public isAuthenticated$: Observable<boolean>;
  public version = environment.version || '-';

  constructor(private readonly store: Store<appReducers.IState>, private readonly cdRef: ChangeDetectorRef) {
    this.isAuthenticated$ = this.store.select(appReducers.getIsAuthenticated);
    // angular doesnt like to change the value 2 times in a one loop of event
    // readmore here :https://blog.angular-university.io/angular-debugging/
    this.showSpinner$ = this.store.select(appReducers.getShowSpinner).pipe(delay(0));
    this.notifications$ = this.store.select(appReducers.getNotifications);
  }

  public ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  public handleCloseNotification(e) {
    this.store.dispatch(new NotificationDestroyItem(e));
  }
}
