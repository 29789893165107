import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromUser from '../../../shared-store';
import { SignOut } from '../../../shared-store/actions/auth/auth.actions';
import { IUser } from '../../../shared/models/user.model';

@Component({
  selector: 'inm-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public user$: Observable<IUser>;
  public idToken$: Observable<string>;
  public userInitialsName$: Observable<string>;

  constructor(private readonly store: Store<fromUser.IState>) {}

  public ngOnInit() {
    this.userInitialsName$ = this.store.pipe(select(fromUser.getCurrentUserInitials));
    this.idToken$ = this.store.pipe(select(fromUser.getIdToken));
  }

  public logOut(token: string): void {
    this.store.dispatch(new SignOut(token));
  }
}
