// todo: need to add test
export function currentDatePlusHours(hours: number) {
  return currentDatePlusSeconds(hours * 60 * 60);
}

// todo: need to add test
export function currentDatePlusSeconds(seconds: number) {
  const date = new Date();
  date.setTime(date.getTime() + seconds * 1000);
  return date;
}
