import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { downloadCustomExcel } from '../../../../lib/excel.lib';
import { getStaticContent } from '../../../../lib/static-content.lib';

@Component({
  template: `
    <div class="inm-overlay"></div>
    <div class="inm-overlay__wrapper">
      <div
        inm-icon
        name="Close"
        color="mid-grey"
        size="small"
        title="close"
        class="inm-overlay__close"
        (click)="dialogRef.close(false)"
      ></div>
      <h4 id="dialogHeader" class="inm-overlay__title text-center inm-red-text">{{ title }}</h4>
      <div class="inm-overlay__ctas">
        We have got errors while validating the uploaded .xls file.
        <span class="dialog-errorlink" (click)="downloadErrorSheets()"><b>Click here</b></span> to download complete
        list of errors.
      </div>
    </div>
  `,
  styleUrls: ['../dialog.component.scss']
})
export class InmUploadErrorDialogComponent implements OnInit {
  public title: string;
  public confirmButtonText: string;
  public cancelButtonText: string;
  public errors: any[];
  public validationErrors: any[];

  constructor(
    public dialogRef: MatDialogRef<InmUploadErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private readonly data
  ) {
    dialogRef.disableClose = true;
  }

  public ngOnInit() {
    this.title = this.data.title;
    this.validationErrors = this.data.value;
  }

  public downloadErrorSheets() {
    downloadCustomExcel(this.validationErrors, `${getStaticContent('upload.fileName')}${new Date().toISOString()}`);
    this.dialogRef.close();
  }
}
