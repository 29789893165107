import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InmButtonColorsEnum } from './inm-button-colors.enum';

@Component({
  selector: 'inm-button',
  templateUrl: './inm-button.component.html',
  styleUrls: ['./inm-button.component.scss']
})
export class InmButtonComponent {
  /**
   * Button text
   * @type {string}
   */
  @Input() public text = '';

  /**
   * Input color
   * @type {string}
   */
  @Input() public color: InmButtonColorsEnum;

  /**
   * Button icon
   * @type {boolean}
   * default is chevron
   */
  @Input() public icon = true;

  /**
   * Button size
   * @type {boolean}
   */
  @Input() public smallSize = false;

  /**
   * Button outline
   * @type {boolean}
   * default is chevron
   */
  @Input() public outline = false;
  /**
   * Button width
   * @type {string}
   */
  @Input() public width = '';

  /**
   * Event emitter
   * @type {EventEmitter<any>}
   * @private
   */
  @Output() public onClickButtonEvent = new EventEmitter();

  public onClickButton() {
    this.onClickButtonEvent.emit();
  }
}
