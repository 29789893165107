import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import 'hammerjs';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(ref => {
    // Ensure Angular destroys itself on hot reloads.
    const ngWindow: any = window;
    if (ngWindow.ngRef) {
      ngWindow.ngRef.destroy();
    }
    ngWindow.ngRef = ref;
  })
  .catch(err => console.log(err)); // tslint:disable-line
