import { HttpErrorResponse } from '@angular/common/http';

export enum NotificationType {
  error = 'error',
  success = 'success',
  warning = 'warning'
}

export enum NotificationMessage {
  http_generic_title = 'HTTP error',
  http_generic_body = '...Ooops, looks like there was an error while fetching for data',
  success = 'Success',
  error = 'Error',
  warning = 'Warning'
}

export interface INotification {
  title: string;
  body?: string;
  link?: string;
  type: NotificationType;
  isMessage?: boolean;
}

export function createErrorNotification(error: HttpErrorResponse, actionName: string): INotification {
  return {
    title:
      error && error.statusText
        ? `${error.status.toString()} ${error.statusText}`
        : NotificationMessage.http_generic_title,
    body:
      error && error.message
        ? `${error.message}: ${actionName}`
        : `${NotificationMessage.http_generic_body}: ${actionName}`,
    link: 'http://inmarsat.com',
    type: NotificationType.error,
    isMessage: messageEmpty(error)
  };
}

export function createErrorMessageNotification(error: string, actionName = ''): INotification {
  return {
    title: NotificationMessage.error,
    body: `${error}. ${actionName}`,
    link: null,
    type: NotificationType.error,
    isMessage: messageEmpty(error)
  };
}

export function createSuccessNotification(message: string, actionName = ''): INotification {
  return {
    title: NotificationMessage.success,
    body: `${message}. ${actionName}`,
    link: null,
    type: NotificationType.success,
    isMessage: messageEmpty(message)
  };
}

export function createWarningNotification(message: string, actionName: string): INotification {
  return {
    title: NotificationMessage.warning,
    body: `${message}: ${actionName}`,
    link: null,
    type: NotificationType.warning,
    isMessage: messageEmpty(message)
  };
}

// Temp method fix for the 400 status error sending blank messages when page starts up
function messageEmpty(m: string | HttpErrorResponse): boolean {
  return m !== undefined && m !== '';
}
