import { Action } from '@ngrx/store';

import { IAddNewContract } from '../../../shared/models/add-new-contract.model';
import { IContractConstant } from '../../../shared/models/contract-constant.model';
import { IContracts } from '../../../shared/models/contract.model';
import { IModifyContractData } from '../../../shared/models/modify-contract-data.model';
import { IPricingScheduleInfo } from '../../../shared/models/pricing-schedule.model';
import { ISearchSuggestion } from '../../../shared/models/search-suggestion.model';
import { IVersionHistory } from '../../../shared/models/version-history.model';

export enum CustomerActionTypes {
  Contract = 'GET_CONTRACTS',
  ContractSuccess = 'GET_CONTRACTS_SUCCESS',
  ContractDetails = 'GET_CONTRACT_DETAIL',
  ContractDetailsSuccess = 'GET_CONTRACT_DETAIL_SUCCESS',
  GetSearchSuggestions = 'GET_SEARCH_SUGGESTIONS',
  GetSearchSeggestionsSuccess = 'GET_SEARCH_SUGGESTIONS_SUCCESS',
  ContractConstant = 'GET_CONTRACT_CONSTANT',
  ContractConstantSuccess = 'GET_CONTRACT_CONSTANT_SUCCESS',
  ModifyCustomerDetails = 'UPDATE_CONTRACT_DETAILS',
  ModifyCustomerDetailsSuccess = 'UPDATE_CONTRACT_DETAILS_SUCCESS',
  PricingScheduleInfo = 'GET_PRICING_SCHEDULE_INFO',
  PricingScheduleInfoSuccess = 'GET_PRICING_SCHEDULE_INFO_SUCCESS',
  VersionHistory = 'GET_VERSION_HISTORY_INFO',
  VersionHistorySuccess = 'GET_VERSION_HISTORY_SUCCESS',
  AddNewContract = 'CREATE_NEW_CONTRACT',
  AddNewContractSuccess = 'CREATE_NEW_CONTRACT_SUCCESS',
  GetCLEExclusionList = 'GET_CLE_EXCLUSION_LIST',
  GetCLEExclusionListSuccess = 'GET_CLE_EXCLUSION_LIST_SUCCESS',
  AddNewCLEIdExclusion = 'ADD_NEW_CLE_ID_EXCLUSION',
  AddNewCLEIdExclusionSuccess = 'ADD_NEW_CLE_ID_EXCLUSION_SUCCESS',
  DeleteCLEIdExclusion = 'DELETE_CLE_ID_EXCLUSION',
  DeleteCLEIdExclusionSuccess = 'DELETE_CLE_ID_EXCLUSION_SUCCESS',
  Error = 'CUSTOMER_ERROR',
  Reset = 'CUSTOMER_RESET_STATE'
}

/**
 * Load Customers
 */
export class Contract implements Action {
  public readonly type = CustomerActionTypes.Contract;
  constructor(public customerId: string, public contractType: string, public searchType: any) {}
}

export class ContractSuccess implements Action {
  public readonly type = CustomerActionTypes.ContractSuccess;
  constructor(public payload: IContracts) {}
}

export class ContractDetails implements Action {
  public readonly type = CustomerActionTypes.ContractDetails;
  constructor(public payload: string, public contractType: string) {}
}
export class ContractDetailsSuccess implements Action {
  public readonly type = CustomerActionTypes.ContractDetailsSuccess;
  constructor(public payload: any) {}
}

export class GetSearchSuggestions implements Action {
  public readonly type = CustomerActionTypes.GetSearchSuggestions;
  constructor(public query: string, public idType: number, public contractType: string, public browse: boolean) {}
}
export class GetSearchSuggestionsSuccess implements Action {
  public readonly type = CustomerActionTypes.GetSearchSeggestionsSuccess;
  constructor(public payload: ISearchSuggestion) {}
}
export class ContractConstant implements Action {
  public readonly type = CustomerActionTypes.ContractConstant;
  constructor(public contractType: string) {}
}

export class ContractConstantSuccess implements Action {
  public readonly type = CustomerActionTypes.ContractConstantSuccess;
  constructor(public payload: IContractConstant) {}
}

export class ModifyCustomerDetails implements Action {
  public readonly type = CustomerActionTypes.ModifyCustomerDetails;
  constructor(public payload: any) {}
}

export class ModifyCustomerDetailsSuccess implements Action {
  public readonly type = CustomerActionTypes.ModifyCustomerDetailsSuccess;
  constructor(public payload: IModifyContractData) {}
}

export class PricingScheduleInfo implements Action {
  public readonly type = CustomerActionTypes.PricingScheduleInfo;
  constructor(public payload: any, public action: string) {}
}

export class PricingScheduleInfoSuccess implements Action {
  public readonly type = CustomerActionTypes.PricingScheduleInfoSuccess;
  constructor(public payload: IPricingScheduleInfo) {}
}

export class VersionHistory implements Action {
  public readonly type = CustomerActionTypes.VersionHistory;
  constructor(public payload: any) {}
}

export class VersionHistorySuccess implements Action {
  public readonly type = CustomerActionTypes.VersionHistorySuccess;
  constructor(public payload: IVersionHistory) {}
}

export class AddNewContract implements Action {
  public readonly type = CustomerActionTypes.AddNewContract;
  constructor(public payload: any) {}
}
export class AddNewContractSuccess implements Action {
  public readonly type = CustomerActionTypes.AddNewContractSuccess;
  constructor(public payload: IAddNewContract) {}
}
export class Error implements Action {
  public readonly type = CustomerActionTypes.Error;
  constructor(public payload: any) {}
}
export class Reset implements Action {
  public readonly type = CustomerActionTypes.Reset;
}
export class GetCLEExclusionList implements Action {
  public readonly type = CustomerActionTypes.GetCLEExclusionList;
  constructor(public payload?: any, public getListBy?: number) {}
}
export class GetCLEExclusionListSuccess implements Action {
  public readonly type = CustomerActionTypes.GetCLEExclusionListSuccess;
  constructor(public payload: any) {}
}
export class AddNewCLEIdExclusion implements Action {
  public readonly type = CustomerActionTypes.AddNewCLEIdExclusion;
  constructor(public cleId: string, public user: string) {}
}
export class AddNewCLEIdExclusionSuccess implements Action {
  public readonly type = CustomerActionTypes.AddNewCLEIdExclusionSuccess;
  constructor(public payload: any) {}
}

export class DeleteCLEIdExclusion implements Action {
  public readonly type = CustomerActionTypes.DeleteCLEIdExclusion;
  constructor(public cleId: string, public user: string) {}
}
export class DeleteCLEIdExclusionSuccess implements Action {
  public readonly type = CustomerActionTypes.DeleteCLEIdExclusionSuccess;
  constructor(public payload: any) {}
}

export type CustomerActions =
  | Contract
  | ContractSuccess
  | ContractDetails
  | ContractDetailsSuccess
  | GetSearchSuggestions
  | GetSearchSuggestionsSuccess
  | ContractConstant
  | ContractConstantSuccess
  | ModifyCustomerDetails
  | ModifyCustomerDetailsSuccess
  | PricingScheduleInfo
  | PricingScheduleInfoSuccess
  | VersionHistory
  | VersionHistorySuccess
  | AddNewContract
  | AddNewContractSuccess
  | GetCLEExclusionList
  | GetCLEExclusionListSuccess
  | AddNewCLEIdExclusion
  | AddNewCLEIdExclusionSuccess
  | DeleteCLEIdExclusion
  | DeleteCLEIdExclusionSuccess
  | Error
  | Reset;
