import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AuthService } from '../auth-service/auth.service';

@Injectable()
export class HttpsRequestInterceptor implements HttpInterceptor {
  constructor(private readonly authService: AuthService) {}
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authService.getAccessTokenAsync().pipe(
      map(token => (token ? token : 'nullToken')),
      switchMap(token => {
        if (token) {
          const cloneReq = this.cloneWithToken(req, token);
          return next.handle(cloneReq);
        }

        return next.handle(req);
      })
    );
  }

  private cloneWithToken(req: HttpRequest<any>, token: string) {
    return req.clone({
      headers: req.headers.set('Accept', 'application/json, application/xml').set('Authorization', `Bearer ${token}`)
    });
  }
}
