import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'inm-table-component',
  templateUrl: './inm-table.component.html',
  styleUrls: ['./inm-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InmTableComponent {
  @Input()
  public headers: Array<{ name: string }>;

  constructor() {
    if (this.headers && this.headers.length === 0) {
      throw new Error('header input length cant be 0');
    }
  }
}
