import { Action } from '@ngrx/store';

export enum MigrationActionTypes {
  ExportContractData = 'GET_CONTRACT_DATA',
  ExportContractDataSuccess = 'GET_CONTRACT_DATA_SUCCESS',
  ClearContractData = 'CLEAR_CONTRACT_DATA',
  ClearContractDataSuccess = 'CLEAR_CONTRACT_DATA_SUCCESS',
  Error = 'MIGRATION_TOOL_ERROR',
  Reset = 'MIGRATION_TOOL_RESET_STATE'
}

export class ExportContractData implements Action {
  public readonly type = MigrationActionTypes.ExportContractData;
  constructor(public searchVal: string, public searchBy: number) {}
}

export class ExportContractDataSuccess implements Action {
  public readonly type = MigrationActionTypes.ExportContractDataSuccess;
  constructor(public payload: object) {}
}

export class ClearContractData implements Action {
  public readonly type = MigrationActionTypes.ClearContractData;
  constructor(public searchVal: string, public searchBy: number) {}
}

export class ClearContractDataSuccess implements Action {
  public readonly type = MigrationActionTypes.ClearContractDataSuccess;
  constructor(public payload: object) {}
}

export class Error implements Action {
  public readonly type = MigrationActionTypes.Error;
  constructor(public payload: any) {}
}
export class Reset implements Action {
  public readonly type = MigrationActionTypes.Reset;
}
export type MigrationActions =
  | ExportContractData
  | ExportContractDataSuccess
  | ClearContractData
  | ClearContractDataSuccess
  | Error
  | Reset;
