import { Component, Input } from '@angular/core';

@Component({
  selector: 'inm-section-title',
  templateUrl: './inm-section-title.component.html',
  styleUrls: ['./inm-section-title.component.scss']
})
export class InmSectionTitleComponent {
  /**
   * Title to display
   */
  @Input()
  public title: string;
  /**
   * The url inside angular app
   */
  @Input()
  public routerUrl: string;
}
