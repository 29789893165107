import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { environment } from '../../../../environments/environment';
import { IState } from '../../../shared-store';
import { RedirectLogin } from '../../../shared-store/actions/auth/auth.actions';
import { getStaticContent } from '../../../shared/lib/static-content.lib';

@Component({
  selector: 'inm-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: [
    '../../../shared/components/inm-panel/inm-panel.component.scss',
    '../../../shared/components/inm-button/inm-button.component.scss',
    './error-page.component.scss'
  ]
})
export class ErrorPageComponent implements OnInit {
  public errorTitle = '';
  public errorDescription = '';
  public errorUrlParameter = '';
  public homepageUrl = environment.redirectUrl;
  public displayBackToHome;
  constructor(private readonly route: ActivatedRoute, private readonly store: Store<IState>) {}

  public ngOnInit() {
    // subscribe to the parameters observable
    this.route.paramMap.subscribe(params => {
      this.errorUrlParameter = params.get('error') || '';
      // check if we have any custome message in content for that error
      if (params.get('errorDescription')) {
        this.errorDescription = getStaticContent(`errors.${this.errorUrlParameter}`) || params.get('errorDescription');
      }
      this.errorTitle = this.errorUrlParameter.replace(/_/g, ' ');
      this.displayBackToHome = this.shouldDisplayBackToHome();
    });
  }
  public shouldDisplayBackToHome(): boolean {
    const errorsNotDisplayBackToHome = ['access_denied', 'login_required', 'api_unavailable'];
    const paramError = this.errorUrlParameter;
    for (const error of errorsNotDisplayBackToHome) {
      if (paramError === error) {
        return false;
      }
    }
    return true;
  }

  public accept() {
    this.store.dispatch(new RedirectLogin());
  }
}
