import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription, UnaryFunction } from 'rxjs';
import { getHasPermission, IState } from '../../shared-store';

@Directive({
  selector: '[inmPermission],[inmPermissionSome],[inmPermissionAll]'
})
export class PermissionDirective implements OnInit, OnDestroy {
  @Input() public inmPermission: string;

  @Input() public inmPermissionSome: string[];

  @Input() public inmPermissionAll: string[];

  private subscription: Subscription;

  constructor(public el: ElementRef<HTMLElement>, private readonly store: Store<IState>) {}

  public ngOnInit() {
    let check: UnaryFunction<Observable<object>, Observable<boolean>>;

    if (this.inmPermission && this.inmPermission.length > 0) {
      check = getHasPermission(true, [this.inmPermission]);
    } else if (this.inmPermissionSome && this.inmPermissionSome.length) {
      check = getHasPermission(false, this.inmPermissionSome);
    } else if (this.inmPermissionAll && this.inmPermissionAll.length) {
      check = getHasPermission(true, this.inmPermissionAll);
    }

    if (check) {
      hideElement(this.el);
      this.subscription = this.store.pipe(check).subscribe(allowed => {
        if (allowed) unhideElement(this.el);
      });
    }
  }

  public ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}

function hideElement(el: ElementRef<HTMLElement>) {
  // fixme: this still shows in dom - perhaps use aria hidden and/or diplay none instead
  el.nativeElement.setAttribute('hidden', 'true');
}

function unhideElement(el: ElementRef<HTMLElement>) {
  el.nativeElement.removeAttribute('hidden');
}
