import { IAddNewContract } from '../shared/models/add-new-contract.model';
import { ICLEList } from '../shared/models/cle-list.model';
import { IContractConstant } from '../shared/models/contract-constant.model';
import { IContractDetailsResponse } from '../shared/models/contract-detail-response.model';
import { IContracts } from '../shared/models/contract.model';
import { IModifyContractData } from '../shared/models/modify-contract-data.model';
import { IPricingScheduleInfo } from '../shared/models/pricing-schedule.model';
import { IProductFamilyItem } from '../shared/models/product-family-item.model';
import { ISearchSuggestion } from '../shared/models/search-suggestion.model';
import { IVersionHistory } from '../shared/models/version-history.model';

export const initialContractValue: IContracts = {
  data: [],
  error: { description: '', code: 0 },
  message: '',
  status: 400
};

export const initialContractDetailValue: IContractDetailsResponse = {
  bacDetails: [],
  contractDetails: [],
  customermetadata: {
    customerId: '',
    cleName: ''
  },
  subscriptionPlansDetails: [],
  soaDetails: [],
  pricingScheduleDetails: []
};

export const initialModifyContractValue: IModifyContractData = {
  message: '',
  status: 0,
  error: { description: '', code: 0 }
};

export const initialAddNewContractValue: IAddNewContract = {
  message: '',
  status: 0,
  contractId: '',
  error: { description: '', code: 0 }
};

export const initialPricingScheduleInfoValue: IPricingScheduleInfo = {
  data: [],
  error: { description: '', code: 0 }
};

export const initialVersionHistoryValue: IVersionHistory = {
  data: null,
  error: { description: '', code: 0 }
};

export const initialCLEListValue: ICLEList = {
  data: [],
  error: { description: '', code: 0 }
};

export const productFamilyConstant: IProductFamilyItem = {
  familyName: '',
  productName: []
};

export const initialSearchSuggestionValue: ISearchSuggestion = {
  data: [],
  error: ''
};

export const initialContractConstant: IContractConstant = {
  productFamily: [productFamilyConstant],
  contractChangeReason: [],
  contractStatus: [],
  standardNonStandardPricing: [],
  contractType: [],
  sectorFamily: [],
  sellingLegalEntity: [],
  territory: [],
  initialTermDuration: [],
  paymentTerms: [],
  productWithdrawalNoticePeriod: []
};
