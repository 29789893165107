import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ID_TOKEN_STR, TOKEN_STR } from '../../app.constants';
import { getIdToken, IState } from '../../shared-store';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  private idTokenSubscription: Subscription;

  constructor(@Inject(DOCUMENT) private readonly document: any, public store: Store<IState>) {}

  /**
   * Returns value of Param
   * @param name
   * @param url
   * @returns {any}
   */
  public getUrlParameter(key, url = this.document.location.href) {
    const regex = new RegExp(`[#&]${key.replace(/[\[\]]/g, '\\$&')}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);
    if (!results) {
      return null;
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  /**
   * @description Creates an okta url string with provided parameters and the oauth base, then redirects
   * @param params
   * @param logout
   */
  public getOktaUrl(params, logout = false) {
    const path = logout ? environment.logoutUrl : environment.oauthUrl;
    const urlParams = Object.entries(params)
      .map(([key, val]) => `${key}=${val}`)
      .join('&');

    return `${environment.oauthBase}${path}?${urlParams}`;
  }

  /**
   * @description This gets a new token from okta in the url, but requires a login.
   */
  public oktaLoginRedirect() {
    this.document.location.href = this.getOktaLoginRedirectUrl();
  }

  public getOktaLoginRedirectUrl() {
    const params = {
      client_id: environment.oauthClientId,
      response_type: `${TOKEN_STR} ${ID_TOKEN_STR}`,
      scope: 'openid profile email',
      redirect_uri: environment.redirectUrl,
      nonce: 'foo',
      state: 'Why is a state required'
    };
    return this.getOktaUrl(params);
  }

  /**
   * @description This gets a new token from okta, but doesn't require a login (prompt:none). If it fails we run oktaLoginRedirect
   *
   * nonce: A value that will be returned in the ID token. It is used to mitigate replay attacks.
   * prompt: Valid values: none, consent, login, or consent and login in either order.
   * state: A value to be returned in the token. The client application can use it to remember the state of its interaction
   * with the end user at the time of the authentication call.
   * scope: openid is required for authentication requests. Other scopes may also be included. (These are pretty badly documented)
   * This either succeeds and returns to the page we were on, or redirects to our URL with these params:
   * error: The error code, if something went wrong.
   * error_description: Additional error information (if any).
   */

  public getOktaSilentRefreshUrl() {
    let idToken: string;

    this.idTokenSubscription = this.store.pipe(select(getIdToken)).subscribe((token: string) => {
      idToken = token;
    });

    const params = {
      client_id: environment.oauthClientId,
      grant_type: 'refresh_token',
      scope: 'openid',
      response_type: 'token',
      response_mode: 'fragment',
      redirect_uri: environment.redirectUrl,
      nonce: 'foo',
      prompt: 'none',
      state: 'state',
      sessionToken: idToken
    };

    return this.getOktaUrl(params);
  }

  /**
   * @description SignOut from logout
   */
  public endSessionAndRedirect(idToken: string) {
    const params = {
      id_token_hint: idToken,
      post_logout_redirect_uri: environment.redirectUrl
    };

    this.document.location.href = this.getOktaUrl(params, true);
  }

  public ngOnDestroy(): void {
    if (this.idTokenSubscription) {
      this.idTokenSubscription.unsubscribe();
    }
  }
}
