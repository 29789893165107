import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'inm-validation-message',
  templateUrl: './inm-validation-message.component.html',
  styleUrls: ['./inm-validation-message.component.scss']
})
export class InmValidationMessageComponent {
  get message() {
    if (this.control.invalid) {
      for (const key in this.control.errors) {
        if (this.control.errors.hasOwnProperty(key) && this.control.errors[key]) {
          const params = { label: this.label, error: this.control.errors[key] };
          return InmValidationMessageComponent.getMessage(key, params) || '';
        }
      }
    }
    return null;
  }
  public static getMessage(key: string, value?) {
    // tslint:disable-next-line:no-parameter-reassignment
    value = value || {};
    return {
      required: `${value.label || 'This field'} is required`,
      pattern: `Please enter a valid ${value.label}`,
      email: ' Email address is not valid',
      minlength: `${value.label || 'This field'} must contain at least ${value.error.requiredLength} characters`,
      maxlength: `${value.label || 'This field'} can contain max ${value.error.requiredLength} characters`,
      duplicate: `${value.label} already exists!`,
      numeric: `${value.label} accepts only numeric values`
    }[key];
  }
  @Input() public label: string;
  @Input() public control: FormControl;
}
