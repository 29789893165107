import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  ACCESS_TOKEN_STR,
  COOKIE_ACCESS_TOKEN,
  COOKIE_EXPIRE_HOURS,
  COOKIE_ID_TOKEN,
  COOKIE_REDIRECT_URL,
  COOKIE_USER,
  ERROR_DESCRIPTION,
  ERROR_STR,
  ID_TOKEN_STR
} from '../../app.constants';
import * as fromApp from '../../shared-store';
import { LoadCurrentUser, RedirectLogin, SetUserTokens } from '../../shared-store/actions/auth/auth.actions';
import { currentDatePlusHours } from '../../shared/lib/date.lib';
import { IAuthResult, IUserAuth } from '../../shared/models/auth-result.model';
import { IUserToken } from '../../shared/models/user-token.model';
import { TokenService } from '../token-service/token.service';

/**
 * JWT helper
 * @type {JwtHelperService}
 */
const jwtService = new JwtHelperService();

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private readonly store: Store<fromApp.IState>,
    private readonly tokenService: TokenService,
    private readonly cookieService: CookieService,
    private readonly router: Router
  ) {}

  public authenticate(): void {
    const authResult: IAuthResult = this.getAuthenticationStatus();

    // we have redirected back from OKTA with some errors from OKTA as url parameter
    if (authResult.error) {
      this.router.navigate([`error/${authResult.error}/${authResult.errorDescription}`]);
    }

    if (authResult.redirect) {
      this.store.dispatch(new RedirectLogin());
      return;
    }

    if (authResult.isLoggedIn && authResult.isTokenInUrl) {
      const redirectUrl = this.cookieService.get(COOKIE_REDIRECT_URL);
      if (redirectUrl && redirectUrl !== '/') {
        // Was already using app
        this.cookieService.remove(COOKIE_REDIRECT_URL);
        this.router.navigate([redirectUrl]);
      } else {
        // First login for app
        this.router.navigate(['.']);
      }
      return;
    }
    // the clientId in the token and env didnt match, go to 403 page
    if (authResult.noMatchingClientId) {
      this.router.navigate([`error/not_authorized/403`]);
    }
  }

  public validateTokens(): { accessToken: string; idToken: string; isTokenInUrl: boolean } {
    // Check to get tokens in url or get them from cookies
    const idToken = this.tokenService.getUrlParameter(ID_TOKEN_STR) || this.cookieService.get(COOKIE_ID_TOKEN);
    const accessToken =
      this.tokenService.getUrlParameter(ACCESS_TOKEN_STR) || this.cookieService.get(COOKIE_ACCESS_TOKEN);

    const isTokenInUrl =
      !!this.tokenService.getUrlParameter(ID_TOKEN_STR) || !!this.tokenService.getUrlParameter(ACCESS_TOKEN_STR);
    if (idToken && accessToken) {
      if (
        // check tokens are for the same user in case access token is from url
        // and idtoken is from cookie. (happens in refresh token)
        // Check if all tokens are in place, not expied
        jwtService.decodeToken(accessToken).email !== undefined &&
        jwtService.decodeToken(idToken).email === jwtService.decodeToken(accessToken).email &&
        !jwtService.isTokenExpired(accessToken) &&
        !jwtService.isTokenExpired(idToken)
      ) {
        return { accessToken, idToken, isTokenInUrl };
      }
      return null;
    }
    return null;
  }

  public getAuthenticationStatus(): IAuthResult | undefined {
    // Stop here if an error has occurred, go to the error page
    const error = this.tokenService.getUrlParameter(ERROR_STR);
    const errorDescription = this.tokenService.getUrlParameter(ERROR_DESCRIPTION);
    if (error) {
      return {
        error,
        errorDescription,
        isLoggedIn: false,
        isTokenInUrl: false,
        redirect: false,
        noMatchingClientId: undefined
      };
    }

    if (this.validateTokens()) {
      const { idToken, accessToken, isTokenInUrl } = this.validateTokens();

      // clientId should match the cid in accessToken
      if (environment.oauthClientId === jwtService.decodeToken(accessToken).cid) {
        // setUserAuth will return IAuthResult with  isLoggedIn: true,
        return this.setUserAuth({
          idToken,
          accessToken,
          isTokenInUrl
        });
      }
      // client ids didnt match
      // noMatchingClientId will result in 403 page no redirect to OKTA
      return { isLoggedIn: false, isTokenInUrl: false, redirect: false, noMatchingClientId: true };
    }

    // neither of jwt tokens nor tokens available in cookie were valid, redirect is true which means
    // we redirect to Okta to obtain a token. may require user to login
    return {
      isLoggedIn: false,
      redirect: true,
      noMatchingClientId: undefined,
      isTokenInUrl: false
    };
  }

  /**
   * Set user details
   * @param {string} idToken
   */
  public setUserAuth(userAuth: IUserAuth): IAuthResult {
    const { accessToken, idToken, isTokenInUrl = false } = userAuth;

    // decode user
    const { name, email, sub, aud, cid }: IUserToken = jwtService.decodeToken(idToken);

    // save to cookie
    const expires = currentDatePlusHours(COOKIE_EXPIRE_HOURS);
    this.cookieService.put(COOKIE_ID_TOKEN, idToken, { expires });
    this.cookieService.put(COOKIE_ACCESS_TOKEN, accessToken, { expires });
    this.cookieService.putObject(COOKIE_USER, { name, email, sub, aud, cid }, { expires });

    // dispatch actions
    this.store.dispatch(new SetUserTokens({ idToken, userToken: { name, email, sub, aud, cid } }));

    this.store.dispatch(new LoadCurrentUser());

    return { isTokenInUrl, isLoggedIn: true, redirect: false, noMatchingClientId: false };
  }

  /**
   * is Logged in check
   * @returns {boolean}
   */
  public isAccessTokenValid(): boolean {
    const accessToken = this.cookieService.get(COOKIE_ACCESS_TOKEN);
    return accessToken ? !jwtService.isTokenExpired(accessToken) : false;
  }

  /**
   * get access token
   * @returns {string}
   */
  public getAccessTokenAsync(): Observable<string | null> {
    if (this.isAccessTokenValid()) {
      return of(this.cookieService.get(COOKIE_ACCESS_TOKEN));
    }

    return of(null);
  }

  public init(): void {
    this.authenticate();
  }

  /**
   * Destroy any trace of user info
   * in Cookies and store
   */
  public destroyAuth(): void {
    this.cookieService.removeAll();
  }

  public signOut(idToken: string): void {
    this.cookieService.removeAll();
    this.tokenService.endSessionAndRedirect(idToken);
  }
}
