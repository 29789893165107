import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { getStaticContent } from '../../shared/lib/static-content.lib';
import { InmAlertDialogComponent } from '../../shared/modules/dialogs/components/inm-alert-dialog/inm-alert-dialog.component';
import { InmConfirmDialogComponent } from '../../shared/modules/dialogs/components/inm-confirm-dialog/inm-confirm-dialog.component';

@Injectable()
export class DialogService {
  public confirmDialogRef: MatDialogRef<InmConfirmDialogComponent>;
  public alertDialogReg: MatDialogRef<InmAlertDialogComponent>;

  constructor(private readonly confirmDialog: MatDialog, private readonly alertDialog: MatDialog) {}

  public confirm(dialogTitle, dialogMessage) {
    this.confirmDialogRef = this.confirmDialog.open(InmConfirmDialogComponent, {
      data: {
        title: dialogTitle,
        message: dialogMessage,
        confirmButtonText: getStaticContent('dialogs.confirm.yes'),
        cancelButtonText: getStaticContent('dialogs.confirm.no')
      }
    });
    return this.confirmDialogRef.afterClosed();
  }

  public alert(alertTitle, alertMessage) {
    this.alertDialogReg = this.alertDialog.open(InmAlertDialogComponent, {
      data: {
        title: alertTitle,
        message: alertMessage,
        confirmButtonText: getStaticContent('dialogs.alert.confirmButtonText')
      }
    });
  }
}
