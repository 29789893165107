import { IClearContract } from '../shared/models/clear-contract.model';
import { IExportContract, IExportContractDataSheets } from '../shared/models/export-contract.model';

export const initialContractDataSheetValue: IExportContractDataSheets = {
  contractContactSheet: [],
  soaSheet: [],
  contractSheet: [],
  psLinkSheet: [],
  billingAccountPsSheet: [],
  bespokeSubsciptionPlansSheet: []
};
export const initialContractValue: IExportContract = {
  data: initialContractDataSheetValue,
  error: { description: '', code: 0 }
};
export const initialClearContractValue: IClearContract = {
  message: '',
  status: 0
};
