import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ExternalPlaceholderComponent } from './components/external-placeholder/external-placeholder.component';
import { InmInputContainerComponent } from './components/forms/inm-input-container/inm-input-container.component';
import { InmValidationMessageComponent } from './components/forms/inm-validation-message/inm-validation-message.component';
import { InmButtonComponent } from './components/inm-button/inm-button.component';
import { InmLoadingSpinnerComponent } from './components/inm-loading-spinner/inm-loading-spinner.component';
import { InmOverlaySpinnerComponent } from './components/inm-loading-spinner/inm-overlay-spinner.component';
import { InmNotificationComponent } from './components/inm-notification/inm-notification.component';
import { InmPanelComponent } from './components/inm-panel/inm-panel.component';
import { InmPopupHttpErrorComponent } from './components/inm-popup-http-error/inm-popup-http-error.component';
import { InmRowItemComponent } from './components/inm-row-item/inm-row-item.component';
import { InmSectionTitleComponent } from './components/inm-section-title/inm-section-title.component';
import { InmTableNoRecordsComponent } from './components/inm-table-no-records/no-records.component';
import { InmTableComponent } from './components/inm-table/inm-table.component';
import { InmToastrComponent } from './components/inm-toastr/inm-toastr.component';
import { InmValidationComponent } from './components/inm-validation/inm-validation.component';
import { NumbersDirective } from './directives/numbers-only/numbers-only.directive';
import { PermissionDirective } from './directives/permission.directive';
import { IconModule } from './icon/icon.module';
import { DialogModule } from './modules/dialogs/dialog.module';
import { StaticContentPipeModule } from './pipes/static-content/static-content-pipe.module';
import { SharedLibModule } from './shared-lib.module';

@NgModule({
  imports: [
    // ANGULAR
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    IconModule,
    // Modules
    DialogModule,
    StaticContentPipeModule,
    // 3RD PARTY LIBS
    SharedLibModule
  ],
  declarations: [
    InmButtonComponent,
    InmPanelComponent,
    InmSectionTitleComponent,
    ExternalPlaceholderComponent,
    InmPopupHttpErrorComponent,
    InmNotificationComponent,
    InmToastrComponent,
    InmRowItemComponent,
    InmTableComponent,
    InmTableNoRecordsComponent,
    PermissionDirective,
    NumbersDirective,
    InmLoadingSpinnerComponent,
    InmOverlaySpinnerComponent,
    // forms
    InmInputContainerComponent,
    InmValidationMessageComponent,
    InmValidationComponent
  ],
  exports: [
    InmButtonComponent,
    InmPanelComponent,
    InmSectionTitleComponent,
    ExternalPlaceholderComponent,
    InmPopupHttpErrorComponent,
    InmNotificationComponent,
    InmToastrComponent,
    InmRowItemComponent,
    IconModule,
    InmTableComponent,
    InmTableNoRecordsComponent,
    InmLoadingSpinnerComponent,
    InmOverlaySpinnerComponent,
    PermissionDirective,
    NumbersDirective,
    // forms
    InmInputContainerComponent,
    InmValidationMessageComponent,
    InmValidationComponent,
    // Pipes
    StaticContentPipeModule,
    // Modules
    DialogModule,
    // ANGULAR
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    // 3RD PARTY LIBS
    SharedLibModule
  ],
  providers: []
})
export class SharedModule {}
