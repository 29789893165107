import { Observable } from 'rxjs';
import { AuthAction, AuthResource } from '../models/auth.model';

export const validResources = new Set(Object.values(AuthResource));
export const validActions = new Set(Object.values(AuthAction));

export function checkPermissionIsValid(resource: AuthResource, action: AuthAction) {
  if (!validResources.has(resource)) {
    throw new Error(`"${resource}" is not a recognised RBAC resource`);
  }

  if (!validActions.has(action)) {
    throw new Error(`"${action}" is not a valid recognised RBAC action`);
  }

  return true;
}

export function checkUserAccess(store: Observable<any>): boolean {
  let check: boolean;
  store.subscribe(allowed => {
    check = allowed;
  });
  return check;
}
