import {
  initialAddNewContractValue,
  initialCLEListValue,
  initialContractConstant,
  initialContractDetailValue,
  initialContractValue,
  initialModifyContractValue,
  initialPricingScheduleInfoValue,
  initialSearchSuggestionValue,
  initialVersionHistoryValue
} from '../../../customers/customer.constant';
import { IAddNewContract } from '../../../shared/models/add-new-contract.model';
import { ICLEList } from '../../../shared/models/cle-list.model';
import { IContractConstant } from '../../../shared/models/contract-constant.model';
import { IContractDetailsResponse } from '../../../shared/models/contract-detail-response.model';
import { IContracts } from '../../../shared/models/contract.model';
import { IModifyContractData } from '../../../shared/models/modify-contract-data.model';
import { IPricingScheduleInfo } from '../../../shared/models/pricing-schedule.model';
import { ISearchSuggestion } from '../../../shared/models/search-suggestion.model';
import { IVersionHistory } from '../../../shared/models/version-history.model';
import { CustomerActions, CustomerActionTypes } from '../../actions/customer/customer.actions';

export interface ICustomersState {
  loaded: boolean;
  loading: boolean;
  error: string;
  contracts: IContracts;
  contractDetails: IContractDetailsResponse;
  contractConstant: IContractConstant;
  modifyContractData: IModifyContractData;
  pricingScheduleInfo: IPricingScheduleInfo;
  versionHistory: IVersionHistory;
  addNewContract: IAddNewContract;
  cleExclusionList: ICLEList;
  addNewCLExclusionId: any;
  deleteCLExclusionId: any;
  getSearchSuggestions: ISearchSuggestion;
}

export const initialState: ICustomersState = {
  loaded: false,
  loading: true,
  error: '',
  contracts: initialContractValue,
  contractDetails: initialContractDetailValue,
  contractConstant: initialContractConstant,
  modifyContractData: initialModifyContractValue,
  pricingScheduleInfo: initialPricingScheduleInfoValue,
  versionHistory: initialVersionHistoryValue,
  addNewContract: initialAddNewContractValue,
  cleExclusionList: initialCLEListValue,
  addNewCLExclusionId: initialContractValue,
  deleteCLExclusionId: initialContractValue,
  getSearchSuggestions: initialSearchSuggestionValue
};

export function customerReducer(state = initialState, action: CustomerActions): ICustomersState {
  switch (action.type) {
    case CustomerActionTypes.Contract: {
      return {
        ...state,
        loading: true,
        contracts: initialContractValue
      };
    }
    case CustomerActionTypes.ContractSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        contracts: action.payload
      };
    }
    case CustomerActionTypes.ContractDetails: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case CustomerActionTypes.ContractDetailsSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        contractDetails: action.payload
      };
    }
    case CustomerActionTypes.ContractConstant: {
      return {
        loaded: false,
        ...state,
        loading: true
      };
    }
    case CustomerActionTypes.ContractConstantSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        contractConstant: action.payload
      };
    }
    case CustomerActionTypes.Error: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.payload,
        contracts: initialContractValue,
        contractDetails: initialContractDetailValue,
        modifyContractData: initialModifyContractValue,
        addNewContract: initialAddNewContractValue,
        cleExclusionList: initialCLEListValue
      };
    }
    case CustomerActionTypes.Reset: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: '',
        contracts: initialContractValue,
        contractDetails: initialContractDetailValue,
        modifyContractData: initialModifyContractValue,
        addNewContract: initialAddNewContractValue,
        cleExclusionList: initialCLEListValue
      };
    }
    case CustomerActionTypes.ModifyCustomerDetails: {
      return {
        ...state,
        loading: false,
        loaded: true
      };
    }
    case CustomerActionTypes.ModifyCustomerDetailsSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        modifyContractData: action.payload
      };
    }
    case CustomerActionTypes.PricingScheduleInfo: {
      return {
        loading: false,
        loaded: true,
        ...state
      };
    }
    case CustomerActionTypes.PricingScheduleInfoSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        pricingScheduleInfo: action.payload
      };
    }
    case CustomerActionTypes.VersionHistory: {
      return {
        ...state,
        loaded: true,
        loading: false
      };
    }
    case CustomerActionTypes.VersionHistorySuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        versionHistory: action.payload
      };
    }
    case CustomerActionTypes.AddNewContract: {
      return {
        loading: false,
        ...state,
        loaded: true
      };
    }
    case CustomerActionTypes.AddNewContractSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        addNewContract: action.payload
      };
    }
    case CustomerActionTypes.GetCLEExclusionList: {
      return {
        ...state,
        loading: true,
        loaded: true
      };
    }
    case CustomerActionTypes.GetCLEExclusionListSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        cleExclusionList: action.payload
      };
    }
    case CustomerActionTypes.AddNewCLEIdExclusion: {
      return {
        loading: true,
        ...state,
        loaded: true
      };
    }
    case CustomerActionTypes.AddNewCLEIdExclusionSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        addNewCLExclusionId: action.payload
      };
    }
    case CustomerActionTypes.DeleteCLEIdExclusion: {
      return {
        loading: true,
        loaded: true,
        ...state
      };
    }
    case CustomerActionTypes.DeleteCLEIdExclusionSuccess: {
      return {
        loading: false,
        loaded: true,
        ...state,
        deleteCLExclusionId: action.payload
      };
    }
    case CustomerActionTypes.GetSearchSuggestions: {
      return {
        loading: true,
        ...state,
        loaded: false
      };
    }
    case CustomerActionTypes.GetSearchSeggestionsSuccess: {
      return {
        loading: false,
        ...state,
        loaded: true,
        getSearchSuggestions: action.payload
      };
    }
    default: {
      return state;
    }
  }
}
