export interface IPermissionResource {
  resource: string;
  actions: string[];
}

export interface IPermission {
  resource: AuthResource;
  scopeType: string;
  scopeValue: string;
  actions: AuthAction[];
}

export interface IAppPermission {
  resource: AuthResource;
  actions: AuthAction[];
}

export enum AuthResource {
  // TODO: Add all the relevant resource
  Relationship_Contract = 'Relationship_Contract',
  Relationship_Contract_ScopeOfAppointment = 'Relationship_Contract.ScopeOfAppointment',
  Relationship_Contract_SubscriptionPlan = 'Relationship_Contract.SubscriptionPlan',
  Relationship_Contract_BillingAccount = 'Relationship_Contract.BillingAccount',
  Relationship_Contract_PricingScheduleLink = 'Relationship_Contract.PricingScheduleLink',
  Relationship_Contract_DEC = 'Relationship_Contract.DEC',
  Relationship_DEC_ScopeOfAppointment = 'Relationship_Contract.ScopeOfAppointment.DEC',
  Relationship_DEC_SubscriptionPlan = 'Relationship_Contract.SubscriptionPlan.DEC',
  Relationship_DEC_BillingAccount = 'Relationship_Contract.BillingAccount.DEC',
  Relationship_DEC_PricingScheduleLink = 'Relationship_Contract.PricingScheduleLink.DEC',
  CMS_UITab_ReferenceData = 'CMS_UITab.reference_data',
  CMS_UITab_Contract = 'CMS_UITab.contracts',
  Order = 'Order'
}

export enum AuthAction {
  Create = 'create',
  Read = 'read',
  Update = 'update',
  Delete = 'clear',
  Suspend = 'suspend',
  Unsuspend = 'unsuspend',
  Export = 'Export',
  Import = 'Import',
  Amend = 'amend'
}
