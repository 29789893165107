import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CookieModule } from 'ngx-cookie';
import { ToastrModule } from 'ngx-toastr';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './core/containers/app.component';
import { CoreModule } from './core/core.module';
import { AuthGuardService } from './services/auth-guard/auth-guard.service';
import { AuthService } from './services/auth-service/auth.service';
import { DeactivateRouteGuard } from './services/deactivate-route/deactivate-route.service';
import { DialogService } from './services/dialog-service/dialog.service';
import { HttpRequestInteceptorError } from './services/http-interceptor-error/http-request-inteceptor-error.service';
import { HttpsRequestInterceptor } from './services/http-interceptor/http-interceptor.service';
import { TitleService } from './services/title-service/title.service';
import { TokenService } from './services/token-service/token.service';
import { UserService } from './services/user-service/user.service';
import { metaReducers, reducers } from './shared-store';
import { CustomRouterStateSerializer } from './shared-store/reducers/router/router.reducer';
import { InmToastrComponent } from './shared/components/inm-toastr/inm-toastr.component';
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [],
  imports: [
    BrowserModule,
    CommonModule,
    NoopAnimationsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CookieModule.forRoot(),
    ToastrModule.forRoot({
      toastComponent: InmToastrComponent,
      preventDuplicates: true
    }),
    /**
     * Shared Module
     * Includes UI common components
     * and 3rd party libraries
     */
    SharedModule,
    /**
     * App routing
     */
    AppRoutingModule,
    /**
     * CoreModule.forRoot() is imported once in the root module and
     * is the actual entry point of the application.
     */
    CoreModule.forRoot(),

    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: { strictStateImmutability: true, strictActionImmutability: true }
    }),

    /**
     * @ngrx/router-store keeps router state up-to-date in the store.
     */
    StoreRouterConnectingModule.forRoot({
      /*
        They stateKey defines the name of the state used by the router-store reducer.
        This matches the key defined in the map of reducers
      */
      stateKey: 'router'
    }),
    /**
     * Store devtools instrument the store retaining past versions of state
     * and recalculating new states. This enables powerful time-travel
     * debugging.
     *
     * To use the debugger, install the Redux Devtools extension for either
     * Chrome or Firefox
     *
     * See: https://github.com/zalmoxisus/redux-devtools-extension
     */
    StoreDevtoolsModule.instrument({
      name: 'TCMS-UI-Client Store DevTools',
      logOnly: environment.production
    }),
    EffectsModule.forRoot([])
  ],
  providers: [
    // Http Interceptor
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpsRequestInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInteceptorError,
      multi: true
    },
    /**
     * The `RouterStateSnapshot` provided by the `Router` is a large complex structure.
     * A custom RouterStateSerializer is used to parse the `RouterStateSnapshot` provided
     * by `@ngrx/router-store` to include only the desired pieces of the snapshot.
     */

    { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
    TitleService,
    AuthService,
    AuthGuardService,
    TokenService,
    UserService,
    DeactivateRouteGuard,
    DialogService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
