export class Obj {
  /**
   * @description
   * Given an object returns the nested property value
   * according to the given path
   * @param object
   * @param path
   */
  public static getPathValue(object: any, path: string[]) {
    return path.reduce((acc: any, cur: string): any => {
      const propExists = typeof acc !== 'undefined' && acc !== null && typeof acc[cur] !== 'undefined';

      return propExists ? acc[cur] : null;
    }, object);
  }

  public static getPathvalueDefault(object: any, path: string[], defaultValue: any) {
    const value = Obj.getPathValue(object, path);

    return typeof value !== 'undefined' && value !== null ? value : defaultValue;
  }
}
