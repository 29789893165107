import * as XLSX from 'xlsx';
import { EXCEL_EXTENTION } from '../../app.constants';

function createNewWorkBook() {
  return XLSX.utils.book_new();
}

function writeSheets(data: any, wb: XLSX.WorkBook) {
  for (const [key] of Object.entries(data)) {
    const wsData = data[key];
    writeSheet(key, wsData, wb);
  }
}

function writeSheet(key: string, wsData: any, wb: XLSX.WorkBook) {
  const newWsName = key;
  /* make worksheet */
  if (wb.Sheets[newWsName]) {
    XLSX.utils.sheet_add_json(wb.Sheets[newWsName], wsData, { origin: -1 });
  } else {
    const ws = XLSX.utils.json_to_sheet(wsData);
    /* Add the worksheet to the workbook */
    XLSX.utils.book_append_sheet(wb, ws, newWsName);
  }
}

function writeExcelFile(wb, filename) {
  /* generate an XLSX file */
  XLSX.writeFile(wb, filename + EXCEL_EXTENTION);
}

export function downloadExcel(data, filename) {
  const wb = createNewWorkBook();
  writeSheets(data, wb);
  writeExcelFile(wb, filename);
}

export function downloadCustomExcel(data, filename) {
  /* create a new blank workbook */
  const wb = createNewWorkBook();

  data.forEach(item => {
    if (Object.keys(item).length > 0) {
      /* make worksheet */
      const wsData = item.inCorrectRow;
      const remarks = item.message;

      const updatedWsData = wsData.map(rowData => {
        const newrow = { ...rowData };
        newrow.remarks = remarks;
        return newrow;
      });

      writeSheet(item.sheetName, updatedWsData, wb);
    }
  });
  /* generate an XLSX file */
  writeExcelFile(wb, filename);
}
