import { Component, Input } from '@angular/core';

@Component({
  selector: 'inm-validation',
  template: `
    <div class="inm-textinput__error" *ngIf="displayMessage">
      <inm-icon name="Error" size="small" color="red" class="inm-textinput__error-icon"></inm-icon>{{ displayMessage }}
    </div>
  `
})
export class InmValidationComponent {
  @Input() public displayMessage: string;
}
