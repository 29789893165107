import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { getStaticContent } from './../../shared/lib/static-content.lib';

@Injectable()
export class TitleService {
  constructor(public titleService: Title, private readonly activatedRoute: ActivatedRoute) {}

  /**
   * Set Doc title
   * Every route is Lazy Loaded !!
   * NOTE : The rule is to set the titles for parent/child routes in the
   * whatever-feature-routing.module.ts file.
   * E.g : Check dashboard-routing.module.ts
   * E.g : Check orders-routing.module.ts
   * E.g : Check account-management-routing.module.ts
   */
  public setDocumentTitle() {
    let child = this.activatedRoute.snapshot.firstChild;

    // tites get repeated and should be deduped
    const titles = new Set();

    while (child) {
      if (child.data && child.data.title) {
        titles.add(getStaticContent(child.data.title));
      }
      child = child.firstChild;
    }

    this.titleService.setTitle(`${getStaticContent('pageTitles.mainTitle')} - ${Array.from(titles).join(' - ')}`);
  }
}
