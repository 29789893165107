import { Action } from '@ngrx/store';

import { IAppPermission } from '../../../shared/models/auth.model';
import { IUserToken } from '../../../shared/models/user-token.model';
import { IUser } from '../../../shared/models/user.model';
/**
 * Auth action Types
 */
export enum AuthActionTypes {
  AUTH_SET_ID_TOKEN = 'AUTH_SET_ID_TOKEN',
  AUTH_SET_USER_TOKENS = 'AUTH_SET_USER_TOKENS',
  AUTH_LOAD_CURRENT_USER = 'AUTH_LOAD_CURRENT_USER',
  AUTH_SET_CURRENT_USER = 'AUTH_SET_CURRENT_USER',
  AUTH_LOAD_USER_ACCESS_SUCCESS = 'AUTH_LOAD_USER_ACCESS_SUCCESS',
  CURRENT_USER_PERMISSIONS_SET = 'CURRENT_USER_PERMISSIONS_SET',
  AUTH_RESET = 'AUTH_RESET',
  AUTH_SIGNOUT = 'AUTH_SIGNOUT',
  AUTH_REDIRECT_LOGIN = 'AUTH_REDIRECT_LOGIN'
}

/**
 * Auth Actions union
 */
export type AuthActionUnion =
  | SetIdToken
  | SetUserTokens
  | LoadCurrentUser
  | RedirectLogin
  | SetCurrentUser
  | CurrentUserPermissionsSet
  | Reset
  | SignOut;

export class SetIdToken implements Action {
  public readonly type = AuthActionTypes.AUTH_SET_ID_TOKEN;
  constructor(public payload: string) {}
}

export class SetUserTokens implements Action {
  public readonly type = AuthActionTypes.AUTH_SET_USER_TOKENS;
  constructor(public payload: { userToken: IUserToken; idToken: string }) {}
}

export class Reset implements Action {
  public readonly type = AuthActionTypes.AUTH_RESET;
}

export class SetCurrentUser implements Action {
  public readonly type = AuthActionTypes.AUTH_SET_CURRENT_USER;
  constructor(public payload: IUser) {}
}

export class LoadCurrentUser implements Action {
  public readonly type = AuthActionTypes.AUTH_LOAD_CURRENT_USER;
}

export class RedirectLogin implements Action {
  public readonly type = AuthActionTypes.AUTH_REDIRECT_LOGIN;
  constructor(public silent = false) {}
}

export class CurrentUserPermissionsSet implements Action {
  public readonly type = AuthActionTypes.CURRENT_USER_PERMISSIONS_SET;
  constructor(public payload: IAppPermission[]) {}
}

export class SignOut implements Action {
  public readonly type = AuthActionTypes.AUTH_SIGNOUT;
  constructor(public tokenToExpire: string) {}
}
