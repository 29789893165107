import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { first, map } from 'rxjs/operators';

import { getHasPermission, IState } from '../../shared-store/';
import { RedirectLogin } from '../../shared-store/actions/auth/auth.actions';
import { AuthService } from '../auth-service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate, CanActivateChild {
  constructor(
    private readonly router: Router,
    private readonly store: Store<IState>,
    protected authService: AuthService
  ) {}

  public canActivate(snapshot: ActivatedRouteSnapshot) {
    if (this.accessTokenStillValid()) {
      if (snapshot.data.access) {
        return this.store.pipe(
          getHasPermission(true, snapshot.data.access.map(([resource, action]) => `${resource}:${action}`)),
          first(),
          map(hasPermission => (hasPermission ? true : this.router.createUrlTree(['/error/not_authorized/403'])))
        );
      }

      return true;
    }

    return false;
  }

  public canActivateChild(childRoute: ActivatedRouteSnapshot) {
    return this.canActivate(childRoute);
  }

  /**
   * @description Return if token valid
   * @returns {boolean}
   */
  public accessTokenStillValid(): boolean {
    if (!this.authService.isAccessTokenValid()) {
      this.store.dispatch(new RedirectLogin(true));
      return false;
    }
    return true;
  }
}
