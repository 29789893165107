import { IAppPermission } from '../../../shared/models/auth.model';
import { IUserToken } from '../../../shared/models/user-token.model';
import { IUser } from '../../../shared/models/user.model';
import { AuthActionTypes, AuthActionUnion } from '../../actions/auth/auth.actions';

/**
 * Interface auth state
 */
export interface IAuthState {
  idToken: string;
  urlFor: string;
  userToken: IUserToken;
  currentUser: IUser;
  currentUserPermissions: IAppPermission[];
}

/**
 * getInitialState returns the default initial state
 * for the generated entity state. Initial state
 * additional properties can also be defined.
 */
export const initialState: IAuthState = {
  idToken: null,
  currentUser: null,
  urlFor: null,
  userToken: null,
  currentUserPermissions: null
};

/**
 * User reducer
 * @param {IAuthState} state
 * @param {AuthActionTypes} action
 * @returns {IAuthState}
 */
export function reducer(state = initialState, action: AuthActionUnion): IAuthState {
  switch (action.type) {
    case AuthActionTypes.AUTH_SET_ID_TOKEN: {
      return {
        ...state,
        idToken: action.payload
      };
    }
    case AuthActionTypes.AUTH_SET_USER_TOKENS: {
      return {
        ...state,
        ...action.payload
      };
    }
    case AuthActionTypes.AUTH_SET_CURRENT_USER: {
      return {
        ...state,
        currentUser: action.payload
      };
    }
    case AuthActionTypes.CURRENT_USER_PERMISSIONS_SET: {
      return {
        ...state,
        currentUserPermissions: action.payload
      };
    }
    case AuthActionTypes.AUTH_RESET: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
