import { DOCUMENT, Location } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie';
import { Observable, of } from 'rxjs';
import { delay, exhaustMap, filter, first, map, switchMap, tap } from 'rxjs/operators';

import { AuthService } from '../../../../app/services/auth-service/auth.service';
import { TokenService } from '../../../../app/services/token-service/token.service';
import { COOKIE_REDIRECT_URL } from '../../../app.constants';
import { UserService } from '../../../services/user-service/user.service';
import { validResources } from '../../../shared/lib/permission.lib';
import { IAppPermission } from '../../../shared/models/auth.model';
import { IUser } from '../../../shared/models/user.model';
import {
  AuthActionTypes,
  CurrentUserPermissionsSet,
  LoadCurrentUser,
  RedirectLogin,
  SetCurrentUser,
  SetUserTokens
} from '../../actions/auth/auth.actions';
import { NotificationShowSpinner } from '../../actions/notifications/notifications.actions';
import { getUserToken, IState } from '../../index';
import { concatSpinner } from '../helpers';

@Injectable()
export class AuthEffects {
  @Effect({ dispatch: false })
  public destroyAuth$: Observable<Action> = this.actions$.pipe(
    ofType(AuthActionTypes.AUTH_SIGNOUT),
    tap(({ tokenToExpire }: any) => this.authService.signOut(tokenToExpire))
  );

  @Effect()
  public redirecLogin$: Observable<Action> = this.actions$.pipe(
    ofType(AuthActionTypes.AUTH_REDIRECT_LOGIN),
    exhaustMap((action: RedirectLogin) => {
      // remember the current location to redirect back to when refresh is done
      if (this.location.path().indexOf('token') === -1) {
        this.cookieService.put(COOKIE_REDIRECT_URL, this.location.path());
      }
      const newUrl = action.silent
        ? this.tokenService.getOktaSilentRefreshUrl()
        : this.tokenService.getOktaLoginRedirectUrl();
      this.document.location.href = newUrl;
      // waits untill the app gets redirected
      return of(new NotificationShowSpinner()).pipe(delay(100000));
    })
  );

  @Effect()
  public loadCurrentUser$: Observable<any> = this.actions$.pipe(
    ofType(AuthActionTypes.AUTH_LOAD_CURRENT_USER),
    concatSpinner((_: LoadCurrentUser) =>
      this.store.select(getUserToken).pipe(
        switchMap(() => this.usersService.getCurrentUserDetail().pipe(map((user: IUser) => new SetCurrentUser(user)))),
        first()
      )
    )
  );

  @Effect()
  public loadCurrentUserPermission$ = this.actions$.pipe(
    ofType(AuthActionTypes.AUTH_SET_USER_TOKENS),
    filter((action: SetUserTokens) => !!action.payload.userToken),
    concatSpinner(() =>
      this.usersService.getUserAccessPermissions().pipe(
        map(permissions => {
          // Only store the permissions for resources the app cares about.
          const toStore = permissions.filter(p => validResources.has(p.resource)) as IAppPermission[];

          return new CurrentUserPermissionsSet(toStore);
        })
      )
    )
  );

  constructor(
    @Inject(DOCUMENT) private readonly document: any,
    private readonly actions$: Actions,
    private readonly authService: AuthService,
    public store: Store<IState>,
    private readonly usersService: UserService,
    public router: Router,
    public route: ActivatedRoute,
    public cookieService: CookieService,
    public tokenService: TokenService,
    public location: Location
  ) {}
}
