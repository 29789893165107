import { ROUTER_NAVIGATED } from '@ngrx/router-store';
import { INotification } from '../../../shared/models/notification.model';
import { NotificationActionTypes, NotificationActionUnion } from '../../actions/notifications/notifications.actions';

/**
 * Interface notifications state
 */
export interface INotificationState {
  notifications: INotification[];
  spinnerCount: number;
  spinnerTokens: Array<symbol | string>;
}

/**
 * getInitialState returns the default initial state
 * for the generated entity state. Initial state
 * additional properties can also be defined.
 */
export const initialState: INotificationState = {
  notifications: [],
  spinnerCount: 0,
  spinnerTokens: []
};

/**
 * notifications reducers
 * @param {State} state
 * @param {OrderActionsUnion} action
 * @returns {State}
 */
export function reducer(state = initialState, action: NotificationActionUnion): INotificationState {
  switch (action.type) {
    case ROUTER_NAVIGATED:
    case NotificationActionTypes.NOTIFICATION_CLEAR_ALL: {
      // remove notifications on navigation & on action
      return {
        ...state,
        notifications: []
      };
    }
    case NotificationActionTypes.NOTIFICATION_SHOW_SPINNER: {
      if (action.payload) {
        if (state.spinnerTokens.indexOf(action.payload) > -1) {
          // Ignore multiple spinner requests with the same token.
          return state;
        }

        return {
          ...state,
          spinnerTokens: [...state.spinnerTokens, action.payload]
        };
      }

      return {
        ...state,
        spinnerCount: state.spinnerCount + 1
      };
    }
    case NotificationActionTypes.NOTIFICATION_HIDE_SPINNER: {
      if (action.payload) {
        return {
          ...state,
          spinnerTokens: state.spinnerTokens.filter(token => token !== action.payload)
        };
      }

      return {
        ...state,
        spinnerCount: Math.max(state.spinnerCount - 1, 0)
      };
    }
    case NotificationActionTypes.NOTIFICATION_ADD_ITEM: {
      if (action.payload.isMessage) {
        return {
          ...state,
          notifications: [action.payload, ...state.notifications]
        };
      }
      return {
        ...state
      };
    }
    case NotificationActionTypes.NOTIFICATION_DESTROY_ITEM: {
      return {
        ...state,
        notifications: state.notifications.filter(item => item !== action.payload)
      };
    }
    default: {
      return state;
    }
  }
}
