import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  template: `
    <div class="inm-overlay"></div>
    <div class="inm-overlay__wrapper">
      <div
        inm-icon
        name="Close"
        color="mid-grey"
        size="small"
        title="close"
        class="inm-overlay__close"
        (click)="dialogRef.close(false)"
      ></div>
      <h4 id="dialogHeader" class="inm-overlay__title inm-dark-blue-text">{{ title }}</h4>
      <p class="inm-overlay__copy">{{ message }}</p>
      <div class="inm-overlay__ctas">
        <button
          id="yesButton"
          class="inm-button inm-button--small inm-button--chevron mx-2"
          (click)="dialogRef.close(true)"
        >
          {{ confirmButtonText }}
        </button>
      </div>
    </div>
  `,
  styleUrls: ['../dialog.component.scss']
})
export class InmAlertDialogComponent implements OnInit {
  public title: string;
  public message: string;
  public confirmButtonText: string;
  public cancelButtonText: string;

  constructor(public dialogRef: MatDialogRef<InmAlertDialogComponent>, @Inject(MAT_DIALOG_DATA) private readonly data) {
    dialogRef.disableClose = true;
  }

  public ngOnInit() {
    this.title = this.data.title;
    this.message = this.data.message;
    this.confirmButtonText = this.data.confirmButtonText;
  }
}
