import { Action } from '@ngrx/store';

export enum AuditLogActionTypes {
  GetAuditLog = 'GET_AUDIT_LOG',
  GetAuditLogSuccess = 'GET_AUDIT_LOG_SUCCESS',
  Error = 'AUDIT_LOG_ERROR',
  Reset = 'AUDIT_LOG_RESET_STATE'
}

/**
 * Load Customers
 */
export class GetAuditLog implements Action {
  public readonly type = AuditLogActionTypes.GetAuditLog;
  constructor(public contractId: string) {}
}

export class GetAuditLogSuccess implements Action {
  public readonly type = AuditLogActionTypes.GetAuditLogSuccess;
  constructor(public payload: any) {}
}
export class Error implements Action {
  public readonly type = AuditLogActionTypes.Error;
  constructor(public payload: any) {}
}
export class Reset implements Action {
  public readonly type = AuditLogActionTypes.Reset;
}

export type AuditLogActions = GetAuditLog | GetAuditLogSuccess | Error | Reset;
