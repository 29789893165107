import { Component, Input } from '@angular/core';
import { IconName } from './icon-names.enum';

export type IconSize = 'small' | 'regular' | 'large' | 'x-large';

export enum IconColor {
  White = 'white',
  MidBlue = 'mid-blue',
  LightBlue = 'light-blue',
  DarkBlue = 'dark-blue',
  DarkGreen = 'dark-green',
  MidGrey = 'mid-grey',
  Red = 'red',
  LightGrey = 'light-grey',
  Orange = 'orange',
  StatusGrey = 'status-grey',
  Inherit = 'inherit',
  MidBlueGrey = 'MidBlueGrey'
}

@Component({
  selector: 'inm-icon, [inm-icon]',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  host: {
    '[class.inm-icon-x-small]': `size == 'x-small'`,
    '[class.inm-icon-small]': `size == 'small'`,
    '[class.inm-icon-large]': `size == 'large'`,
    '[class.inm-icon-x-large]': `size == 'x-large'`,
    '[class.inm-icon-color--white]': `color == 'white'`,
    '[class.inm-icon-color--mid-blue]': `color == 'mid-blue'`,
    '[class.inm-icon-color--light-blue]': `color == 'light-blue'`,
    '[class.inm-icon-color--dark-blue]': `color == 'dark-blue'`,
    '[class.inm-icon-color--dark-green]': `color == 'dark-green'`,
    '[class.inm-icon-color--mid-grey]': `color == 'mid-grey'`,
    '[class.inm-icon-color--red]': `color == 'red'`,
    '[class.inm-icon-color--light-grey]': `color == 'light-grey'`,
    '[class.inm-icon-color--orange]': `color == 'orange'`,
    '[class.inm-icon-color--status-grey]': `color == 'status-grey'`,
    '[class.inm-icon-color--mid-blue-grey]': `color == 'MidBlueGrey'`,
    '[class.inm-icon-color--inherit]': `color == 'inherit'`
  }
})
export class IconComponent {
  @Input('inm-icon')
  set inmIcon(value: IconName) {
    this.name = value;
  }

  @Input() public color = IconColor.MidBlue;

  @Input() public name: IconName;

  @Input() public title: string;

  @Input() public size: IconSize = 'regular';
}
