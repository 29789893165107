import { Inject, InjectionToken, Optional, Pipe, PipeTransform } from '@angular/core';
// tslint:disable-next-line: no-implicit-dependencies
import enJson from '../../../../assets/i18n/en.json';
import { Obj } from '../../lib/obj.lib';

export const STATIC_CONTENT_CONTEXT = new InjectionToken('STATIC_CONTENT_CONTEXT');

@Pipe({
  name: 'staticContent'
})
export class StaticContentPipe implements PipeTransform {
  public content = enJson;

  public getPathValue = Obj.getPathValue;
  constructor(
    @Inject(STATIC_CONTENT_CONTEXT)
    @Optional()
    private readonly context: string
  ) {}

  public transform(key: string): any {
    let fullKey: string;

    if (key.startsWith('.')) {
      if (!this.context) {
        throw new Error(
          'StaticContentPipe got a relative key (prefixed with "."), but there was no context was provided by the parent component using the STATIC_CONTENT_CONTEXT token.'
        );
      }

      fullKey = this.context + key;
    } else {
      fullKey = key;
    }

    const path = fullKey.split('.');

    return this.getPathValue(this.content, path);
  }
}
