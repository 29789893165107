import { Component, Input } from '@angular/core';

@Component({
  selector: 'inm-row-item',
  templateUrl: './inm-row-item.component.html',
  styleUrls: ['./inm-row-item.component.scss']
})
export class InmRowItemComponent {
  @Input()
  public small = false;
  @Input()
  public label;
  @Input()
  public value;
}
