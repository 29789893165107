import * as fromRouter from '@ngrx/router-store';
import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer,
  select
} from '@ngrx/store';

import { pipe } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { checkPermissionIsValid } from '../shared/lib/permission.lib';
import { AuthAction, AuthResource } from '../shared/models/auth.model';
import * as fromAuditLog from './reducers/audit-log/audit-log.reducer';
import * as fromAuth from './reducers/auth/auth.reducer';
import * as fromCustomer from './reducers/customer/customer.reducer';
import * as fromMaintenance from './reducers/maintenance-tool/maintenance-tool.reducer';
import * as fromMigration from './reducers/migration/migration.reducer';
import * as fromNotifications from './reducers/notifications/notifications.reducer';
import { IRouterStateUrl } from './reducers/router/router.reducer';
import * as fromUpload from './reducers/upload-file/upload-file.reducer';

/********************************************************************************
 * APP STATE INTERFACE
 *******************************************************************************/
// Extends the app state to include the product feature.
// This is required because products are lazy loaded.
// So the reference to ProductState cannot be added to app.state.ts directly.
export interface IState {
  router: fromRouter.RouterReducerState<IRouterStateUrl>;
  auth: fromAuth.IAuthState;
  customer: fromCustomer.ICustomersState;
  notifications: fromNotifications.INotificationState;
  uploadfile: fromUpload.IUploadFileState;
  exportContract: fromMigration.IMigrationState;
  modifyPredefinedvalues: fromMaintenance.IMaintenanceToolState;
  getAuditLog: fromAuditLog.IAuditLogState;
}

/******************************************************
 * APP REDUCERS
 *****************************************************/
export const reducers: ActionReducerMap<IState> = {
  router: fromRouter.routerReducer,
  auth: fromAuth.reducer,
  customer: fromCustomer.customerReducer,
  notifications: fromNotifications.reducer,
  uploadfile: fromUpload.uploadFileReducer,
  exportContract: fromMigration.migrationReducer,
  modifyPredefinedvalues: fromMaintenance.maintenanceToolReducer,
  getAuditLog: fromAuditLog.auditLogReducer
};

/******************************************************
 * APP STATE SELECTOR
 *****************************************************/
export const getAppState = (state: IState) => state;

/********************************************************************************
 * SELECTORS
 *******************************************************************************/
/**
 * The createFeatureSelector function selects a piece of state from the root of the state object.
 * This is used for selecting feature states that are loaded eagerly or lazily.
 */

// Upload File

export const getUploadFileFeatureState = createFeatureSelector<fromUpload.IUploadFileState>('uploadfile');
export const getUploadResponse = createSelector(
  getUploadFileFeatureState,
  state => state
);

// Customer
export const getCustomerFeatureState = createFeatureSelector<fromCustomer.ICustomersState>('customer');
export const getContracts = createSelector(
  getCustomerFeatureState,
  state => state.contracts
);
export const getContractDetails = createSelector(
  getCustomerFeatureState,
  state => state.contractDetails
);
export const getSearchSuggestions = createSelector(
  getCustomerFeatureState,
  state => state.getSearchSuggestions
);
export const getContractConstant = createSelector(
  getCustomerFeatureState,
  state => state.contractConstant
);
export const getCustomerError = createSelector(
  getCustomerFeatureState,
  state => state.error
);
export const updateContractData = createSelector(
  getCustomerFeatureState,
  state => state.modifyContractData
);
export const getPricingInfo = createSelector(
  getCustomerFeatureState,
  state => state.pricingScheduleInfo
);
export const getVersionHistory = createSelector(
  getCustomerFeatureState,
  state => state.versionHistory
);
export const createNewContract = createSelector(
  getCustomerFeatureState,
  state => state.addNewContract
);
export const getCleExclusionList = createSelector(
  getCustomerFeatureState,
  state => state.cleExclusionList
);
export const addNewCLEExclusionId = createSelector(
  getCustomerFeatureState,
  state => state.addNewCLExclusionId
);
export const deleteCLEExclusionId = createSelector(
  getCustomerFeatureState,
  state => state.deleteCLExclusionId
);

// Audit log
export const getAuditLogFeatureState = createFeatureSelector<fromAuditLog.IAuditLogState>('getAuditLog');
export const getAuditLog = createSelector(
  getAuditLogFeatureState,
  state => state.auditLog
);

// Migration
export const getMigrationFeatureState = createFeatureSelector<fromMigration.IMigrationState>('exportContract');
export const getContractDataAsJson = createSelector(
  getMigrationFeatureState,
  state => state.exportContractData
);
export const clearContractData = createSelector(
  getMigrationFeatureState,
  state => state.clearContractData
);
export const getMigrationError = createSelector(
  getMigrationFeatureState,
  state => state.error
);

// New CLE
export const modifyPredefinedValuesFeatureState = createFeatureSelector<fromMaintenance.IMaintenanceToolState>(
  'modifyPredefinedvalues'
);
export const modifyPredefinedvalues = createSelector(
  modifyPredefinedValuesFeatureState,
  state => state.maintenanceTool
);

// AUTH
export const getAuthState = createFeatureSelector<fromAuth.IAuthState>('auth');

export const getIsAuthenticated = createSelector(
  getAuthState,
  state => !!state.userToken && !!state.idToken && state.currentUserPermissions !== null
);

export const getUserToken = createSelector(
  getAuthState,
  state => state.userToken
);

export const getCurrentUser = createSelector(
  getAuthState,
  state => state.currentUser
);

export const getCurrentUserInitials = createSelector(
  getCurrentUser,
  currentUser => (currentUser ? `${currentUser.firstName[0]}${currentUser.lastName[0]}` : '-')
);

export const getCurrentUserPermissions = createSelector(
  getAuthState,
  state => state.currentUserPermissions
);

const permissionRx = new RegExp('^(.+):(.+)$');

/**
 * This selector builds a function that checks whether a permission requirement,
 * of the form "resource:action" is present in the current user's permissions.
 *
 * It exists so the creation of this function can be memoized and only ran when the
 * user's pemrissions change.
 */
export const getPermissionChecker = createSelector(
  getCurrentUserPermissions,
  state => {
    if (state === null) {
      return null;
    }

    const permissionMap = new Map<AuthResource, Set<AuthAction>>();

    for (const permission of state) {
      let actions = permissionMap.get(permission.resource);

      if (!actions) {
        actions = new Set<AuthAction>();
        permissionMap.set(permission.resource, actions);
      }

      permission.actions.forEach(a => actions.add(a));
    }

    // TODO: Check will need to take scopes and scope values into account as well.
    return (requiredPermission: string) => {
      const matched = permissionRx.exec(requiredPermission);

      if (!matched) {
        throw new Error(`Required permission "${requiredPermission}" is not in the correct format.`);
      }

      const [, resource, action] = (matched as any) as [any, AuthResource, AuthAction];

      if (!environment.production) {
        // Check for typos in resource / action names
        checkPermissionIsValid(resource, action);
      }

      return permissionMap.has(resource) && permissionMap.get(resource).has(action);
    };
  }
);

/**
 * Selector factory returning a selector that selects true or false depending on
 * whether the user has some / all of the required permissions.
 */
export const getHasPermission = (requireAll: boolean, permissions: string[]) =>
  pipe(
    select(getPermissionChecker),
    filter(checker => !!checker),
    map(requireAll ? checker => permissions.every(checker) : checker => permissions.some(checker))
  );

export const getIdToken = createSelector(
  getAuthState,
  state => state.idToken
);

// CUSTOMER
export const getCustomerState = createSelector(
  getAppState,
  state => state.customer
);

// NOTIFICATIONS
const getNotificationsState = createFeatureSelector<fromNotifications.INotificationState>('notifications');

export const getNotifications = createSelector(
  getNotificationsState,
  state => state.notifications
);

export const getNotification = createSelector(
  getNotificationsState,
  notifications => notifications[0]
);

export const getShowSpinner = createSelector(
  getNotificationsState,
  notifications => notifications.spinnerCount > 0 || notifications.spinnerTokens.length > 0
);

// console.log all actions
export function logger(reducer: ActionReducer<IState>): ActionReducer<IState> {
  // tslint:disable-next-line:only-arrow-functions
  return function(state: IState, action: any): IState {
    return reducer(state, action);
  };
}

/******************************************************
 * META REDUCERS
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 *****************************************************/
export const metaReducers: Array<MetaReducer<IState>> = !environment.production ? [logger] : [];
