import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'inm-panel',
  templateUrl: './inm-panel.component.html',
  styleUrls: ['./inm-panel.component.scss']
})
export class InmPanelComponent {
  /**
   * Panel color
   * @type {string}
   */
  @Input()
  public color = 'white';
  /**
   * Panel title
   * @type {string}
   */
  @Input()
  public title = '';
  /**
   * add/remove padding
   * @type {boolean}
   */
  @Input()
  public padded = true;
  /**
   * add/remove padding
   * @type {boolean}
   */
  @Input()
  public paddedTable = false;

  /**
   * Panel subtitle
   * @type {string}
   */
  @Input()
  public subtitle: string;

  /**
   * Primary button text
   * @type {string}
   */
  @Input()
  public primaryButtonText: string;
  /**
   * Input height for the panel
   * @type {string}
   */
  @Input()
  public heightPan = '100%';
  /**
   *
   * @type {EventEmitter<any>}
   */
  @Output()
  public onClickPrimaryButtonEvent = new EventEmitter();
  /**
   * Outline button text
   * @type {string}
   */
  @Input()
  public secondaryButtonText: string;
  /**
   *
   * @type {EventEmitter<any>}
   */
  @Output()
  public onClickSecondaryButtonEvent = new EventEmitter();

  public onClickPrimaryButton() {
    this.onClickPrimaryButtonEvent.emit();
  }

  public onClickSecondaryButton() {
    this.onClickSecondaryButtonEvent.emit();
  }
}
