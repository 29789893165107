import { AuditLogActions, AuditLogActionTypes } from '../../actions/audit-log/audit-log.actions';

export interface IAuditLogState {
  loaded: boolean;
  loading: boolean;
  auditLog: any;
}

export const initialState = {
  loaded: false,
  loading: false,
  auditLog: {}
};

export function auditLogReducer(state = initialState, action: AuditLogActions): IAuditLogState {
  switch (action.type) {
    case AuditLogActionTypes.GetAuditLog: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }

    case AuditLogActionTypes.GetAuditLogSuccess: {
      return {
        ...state,
        loaded: true,
        loading: false,
        auditLog: action.payload
      };
    }

    case AuditLogActionTypes.Reset: {
      return {
        ...state,
        loaded: false,
        loading: false,
        auditLog: {}
      };
    }
    default: {
      return state;
    }
  }
}
