import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ROUTES } from '../../../app.constants';
import { getStaticContent } from '../../../shared/lib/static-content.lib';
import { AuthAction, AuthResource } from '../../../shared/models/auth.model';

export interface ISideMenuItem {
  label: string;
  url: string;
  isCollapsed?: boolean;
  children: ISideMenuItem[];
  leavesDUE?: boolean;
  permissions?: string[];
}
@Component({
  selector: 'inm-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent {
  public sideMenu: ISideMenuItem[] = [
    {
      label: getStaticContent('sidemenu.home'),
      url: `${ROUTES.HOME}`,
      children: []
    },
    {
      label: getStaticContent('sidemenu.upload'),
      url: `${ROUTES.UPLOAD}`,
      permissions: [`${AuthResource.Relationship_Contract}:${AuthAction.Import}`],
      children: []
    },
    {
      label: getStaticContent('sidemenu.browse'),
      url: ``,
      isCollapsed: true,
      permissions: [`${AuthResource.Relationship_Contract_DEC}:${AuthAction.Read}`],
      children: [
        {
          label: getStaticContent('sidemenu.contract'),
          url: `/${ROUTES.CONTRACT}`,
          permissions: [`${AuthResource.Relationship_Contract}:${AuthAction.Read}`],
          children: []
        },
        {
          label: getStaticContent('sidemenu.buContract'),
          url: `/${ROUTES.BU_CONTRACT}`,
          permissions: [`${AuthResource.Relationship_Contract_DEC}:${AuthAction.Read}`],
          children: []
        },
        {
          label: getStaticContent('sidemenu.buExclusions'),
          url: `/${ROUTES.BU_EXCLUSIONS}`,
          children: [],
          permissions: [`${AuthResource.Relationship_Contract_DEC}:${AuthAction.Read}`]
        }
      ]
    },
    {
      label: getStaticContent('sidemenu.cmsAdmin'),
      url: ``,
      isCollapsed: true,
      permissions: [`${AuthResource.CMS_UITab_Contract}:${AuthAction.Export}`],
      children: [
        {
          label: getStaticContent('sidemenu.migrationTool'),
          url: `/${ROUTES.MIGRATION}`,
          children: [],
          permissions: [`${AuthResource.CMS_UITab_Contract}:${AuthAction.Export}`]
        },
        {
          label: getStaticContent('sidemenu.maintenanceTool'),
          url: `/${ROUTES.MAINTENANCE}`,
          children: [],
          permissions: [`${AuthResource.CMS_UITab_ReferenceData}:${AuthAction.Read}`]
        }
      ]
    }
  ];

  public url = '/';

  constructor(private readonly router: Router) {
    this.navigationEndListener();
  }

  /**
   * listen to router events
   */
  public navigationEndListener() {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.url = this.router.url;
        const activeItem = this.sideMenu.find(item => item.children.length > 0 && this.url.startsWith(`/${item.url}/`));
        if (activeItem) {
          activeItem.isCollapsed = false;
        }
      }
    });
  }

  public collapseSideMenus(menu) {
    this.sideMenu.forEach(element => {
      element.isCollapsed = menu.label !== element.label || !menu.isCollapsed;
    });
  }
}
