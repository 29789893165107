import { DateTime } from 'luxon';
import { BsDatepickerConfig } from 'ngx-bootstrap';
import { AuthAction, AuthResource } from './shared/models/auth.model';

export const TOKEN_STR = 'token';
export const ID_TOKEN_STR = 'id_token';
export const ACCESS_TOKEN_STR = 'access_token';
export const COOKIE_ACCESS_TOKEN = 'cms_access_token';
export const COOKIE_ID_TOKEN = 'cms_id_token';
export const COOKIE_USER = 'cms_user';
export const COOKIE_REDIRECT_URL = 'cms_redirectUrl';
export const URL_FOR_STR = 'url-for';
export const USER_STR = 'user';
export const ERROR_STR = 'error';
export const ERROR_DESCRIPTION = 'error_description';
export const DEFAULT_REDIRECT = 'welcome';
export const ACCESS_DENIED = 'access_denied';
export const CUSTOM_INVALID_DATE = 'custom locale';
export const UTC_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ssZZ';
export const UI_CURRENT_DATE = DateTime.local().toFormat(UTC_DATE_FORMAT);
export const UI_DATE_FORMAT = 'DD/MM/YYYY';
export const MONTH_YEAR = 'MMM YYYY';
export const CONTRACT_DATE_FORMAT = 'LLL dd yyyy';
export const UNDEFINED = 'undefined';
export const DEFAULT_COUNTRY = 'United Kingdom of Great Britain and Northern Ireland';
export const DEFAULT_ISO_COUNTRY_CODE = 'GB';
export const COOKIE_EXPIRE_HOURS = 7;

export const DATE_PICKER_CONFIG: Partial<BsDatepickerConfig> = {
  rangeInputFormat: UI_DATE_FORMAT,
  dateInputFormat: UI_DATE_FORMAT,
  containerClass: 'theme-blue',
  displayMonths: 1
};

export const ROUTES = {
  // HOME
  ROOT: '',
  HOME: 'home',
  CMS: 'CMS',

  // UPLOAD
  UPLOAD: 'upload',

  // CONTRACT
  CONTRACT: 'contract',
  CONTRACT_DETAILS: ':contractId',

  // Business User CONTRACT
  BU_CONTRACT: 'bu_contract',
  BU_EXCLUSIONS: 'bu_exclusions',

  // EXPORT
  MIGRATION: 'migration',

  // MAINTENANCE-TOOL
  MAINTENANCE: 'maintenance',

  // HELPER ROUTES
  EXTERNAL: 'external-link/:destination',
  NOT_FOUND: 'error/page_not_found/404',

  // ERROR
  ERROR: 'error/:error/:errorDescription'
};
export const UK_DATE_FORMAT = 'dd/LL/yyyy';
export const STANDARD_DATE_FORMAT = 'LL/dd/yyyy';
export const TEXT_INPUT_PATTERN = '^(?! )([\\w\\W])+$';
export const INPUT_ID_PATTERN = '^(?! )[a-zA-Z0-9_-]*$';
export const EMAIL_INPUT_PATTERN = '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$';
export const NUMERIC_INPUT_PATTERN = '^(?! )[0-9]*$';
export const MODIFY_ACTIONS = ['update', 'amend', 'create'];
export const EXCEL_EXTENTION = '.xlsx';

// RBAC resources and actions
export const MODIFY_PERMISSIONS_NORMAL_CONTRACT = [
  `${AuthResource.Relationship_Contract}:${AuthAction.Update}`,
  `${AuthResource.Relationship_Contract}:${AuthAction.Create}`,
  `${AuthResource.Relationship_Contract}:${AuthAction.Amend}`,
  `${AuthResource.Relationship_Contract_ScopeOfAppointment}:${AuthAction.Create}`,
  `${AuthResource.Relationship_Contract_PricingScheduleLink}:${AuthAction.Create}`,
  `${AuthResource.Relationship_Contract_SubscriptionPlan}:${AuthAction.Create}`
];

export const MODIFY_PERMISSIONS_BU_CONTRACT = [
  `${AuthResource.Relationship_Contract_DEC}:${AuthAction.Update}`,
  `${AuthResource.Relationship_Contract_DEC}:${AuthAction.Create}`,
  `${AuthResource.Relationship_Contract_DEC}:${AuthAction.Amend}`,
  `${AuthResource.Relationship_DEC_ScopeOfAppointment}:${AuthAction.Create}`,
  `${AuthResource.Relationship_DEC_PricingScheduleLink}:${AuthAction.Create}`,
  `${AuthResource.Relationship_DEC_SubscriptionPlan}:${AuthAction.Create}`
];
